import {useNavigate} from "react-router-dom";
import {Container} from "@mui/material";
import PositionForm from "../feature/position-form/PositionForm";

export function PositionCreationPage() {

  const navigate = useNavigate()
  return <>
    <Container sx={{my: 4}} maxWidth={'lg'}>
      <PositionForm onSubmit={(posId) => {

        navigate('../position/' + posId, {replace: true});

      }} onBackClick={() => navigate('../positions')}/>
    </Container>

  </>
}