import {
  useFutureMeetingsLazyQuery, useFutureMeetingsSuspenseQuery,
} from "../../../../../api/sdl";
import React from "react";
import {MeetingDetailsItem} from "../../../../../entity";

export default function useMeetingsByPositionId(positionId: string) {
  const {data} = useFutureMeetingsSuspenseQuery({variables: {positionId}});



  const meetings: MeetingDetailsItem[] = React.useMemo(() => {
    return data?.futureMeetings || [];
  }, [data])

  return meetings;
}
