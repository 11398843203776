import React, {PropsWithChildren} from "react";
import {RescheduleMeetingClickHandlerContext} from "../../../entity";
import {useNavigate} from "react-router-dom";
import {useIsAdmin} from "../../../shared";
import {rescheduleLink} from "../../../entity/meeting/lib/utils";

export const RescheduleMeetingClickHandlerContextProvider = (props: PropsWithChildren<any>) => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  return <RescheduleMeetingClickHandlerContext.Provider value={{
    handler: (params: {positionId: string, positionMatchId: string, meetingId: string}) => {
      navigate(rescheduleLink(isAdmin, params))
    }
  }}>
    {props.children}
  </RescheduleMeetingClickHandlerContext.Provider>
}