import {Box, Button, Stack, Typography} from "@mui/material";
import {CompositeAnswerApiData} from "./lib/types";
import React, {useMemo, useState} from "react";
import {AnswerType} from "../../../../api/sdl";
import {VideoAnswer} from "./ui/VideoAnswer";
import {TextAnswer} from "./ui/TextAnswer";
import VideoRecordModal from "../../../video-record-modal/VideoRecordModal";
import {Editable} from "../../../../shared";
import {TextAnswerModal} from "./ui/TextAnswerModal";

interface Props extends Editable {
  answers: CompositeAnswerApiData[],
  onUpdate: () => void,
  contractorId: string,
}

export function CombinedAnswers(props: Props) {

  const [short, setShort] = useState(true)
  const [recordOpen, setRecordOpen] = useState(false);
  const [recordingTitle, setRecordingTitle] = useState<string>("")
  const [recordingId, setRecordingId] = useState<string>()

  const [textOpen, setTextOpen] = useState(false)
  const [modalAnswerValue, setModalAnswerValue] = useState<string>("")

  const displayed = useMemo(() => {

    if(props.editable) {
      // Always show full list on edit
      return props.answers
    }
    else {
      if(short) {
        return props.answers.filter(a => !!a.videoAnswer || !!a.textAnswer).slice(0, 10)
      } else {
        return props.answers.filter(a => !!a.videoAnswer || !!a.textAnswer)
      }
    }
  }, [short, props.answers]);

  const handleRecordClick = (item: CompositeAnswerApiData) => {
    setRecordOpen(true)
    setRecordingTitle(item.title)
    setRecordingId(item!.questionId)
  }

  const refresh = () => {
    setRecordOpen(false)
    setRecordingId(undefined)
    setRecordingTitle("")
    props.onUpdate()
  }

  const handleTextEdickClick = (item: CompositeAnswerApiData) => {

    setTextOpen(true)
    setRecordingTitle(item.title)
    setRecordingId(item!.questionId)
    setModalAnswerValue(item?.textAnswer?.answer || "")
  }
  return <>
    {props.editable && recordingId && textOpen &&
      <TextAnswerModal
        contractorId={props.contractorId}
        title={recordingTitle}
        questionId={recordingId}
        open={textOpen}
        answer={modalAnswerValue}
        onUpdate={refresh}
        onClose={() => {
          setTextOpen(false)
        }}
      />
    }
    {props.editable &&
      <VideoRecordModal open={recordOpen}
                      title={recordingTitle}
                      onRecorded={refresh}
                      contractorId={props.contractorId}
                      videoQuestionId={recordingId}
                      onClose={() => {
                        setRecordOpen(false);
                      }} />
    }
    <Typography variant={"h3"}>Sales Assessment</Typography>
    <Stack spacing={0}>
      {displayed.map(answer => (
        answer.type === AnswerType.Text
          ? <TextAnswer item={answer} key={answer.questionId} editable={props.editable} onEdit={() => handleTextEdickClick(answer)}/>
          : <VideoAnswer item={answer} key={answer.questionId} editable={props.editable} onRecordClick={() => {handleRecordClick(answer)}}/>
      ))}

    </Stack>
    {!props.editable &&
      <Box sx={{display: "flex", justifyContent: "center"}}>
       <Button onClick={() => setShort(!short)}>{short?"see more answers":"see less answers"}</Button>
      </Box>
    }
  </>
}
