import {Box, Button, Stack} from "@mui/material";
import React from "react";
import {MeetingDetails} from "./MeetingDetails";
import {MeetingDetailsItem} from "../lib/types";
import {scheduleMeetingLink} from "../lib/utils";
import {useNavigate} from "react-router-dom";
import {useIsMobile} from "../../../shared";

const PastMeetingRowItem = ({item, hired}: {item: MeetingDetailsItem, hired: boolean}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile()

  return <Box sx={{display: 'flex', alignItems: 'center', gap: 3, background: 'white', border: '1px solid #E4E4E4', borderRadius: '10px', p: '16px 18px', mb: '20px'}}>
    {!isMobile &&
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#E4E4E7', borderRadius: '24px', width: 48, height: 48}}>
        <Box component={'img'} src={`/img/teenyicons/appointments.svg`} width={22} height={22} />
      </Box>
    }
    <Stack direction={{xs: "column", md: "row"}} sx={{width: '100%', justifyContent: "space-between"}} gap={{xs: 2, sm: 0}}>
      <MeetingDetails item={item} />
      <Stack direction={{xs: "row", md: "row"}}>
        {!hired && <Button data-test='reschedule' onClick={() => navigate(scheduleMeetingLink({positionId: item.positionId, positionMatchId: item.positionMatchId}))}>Schedule next meeting</Button>}
        {!hired && <Button data-test='hire' onClick={() => navigate('/company/hire/' + item.positionId + '/' + item.positionMatchId)}>Hire contractor</Button>}
      </Stack>
    </Stack>
  </Box>
}

export {PastMeetingRowItem}