import React from "react";
import {AttributeCategory, PositionOutput} from "../../api/sdl";
import {Box, Stack, Typography} from "@mui/material";
import TableRow from "../../shared/components/TableRow";
import {formatLocation} from "../../entity/position/lib/util";
import {showRange, toCurrency} from "../../shared";
import * as _ from "lodash";
import {DateTime} from "luxon";
import {PositionApiData} from "../../shared/hooks/usePositionByIdApi";

export const PositionViewJobDetails = ({position}: {position?: PositionApiData}) => (
  <Box sx={{background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px'}}>
    <Typography sx={{color: '#8A8C8D', fontWeight: 500, mb: 3}}>Job details</Typography>
    <Stack spacing={2.5}>
      <Box sx={{display: 'table'}}>
        <TableRow image={`/img/teenyicons/pin.svg`}
                  title={'Location:'}
                  content={<>
                    {position?.locations.map((l, i) => <Typography key={i} sx={{color: 'text.primary'}} >{formatLocation(l)}</Typography>)}
                  </>}
        />

        {!!position?.attributesExpanded?.filter(a => a.category === AttributeCategory.ExpectedContractLength).length
          &&

            <TableRow image={`/img/teenyicons/hourglass.svg`}
                      title={'Expected length:'}
                      content={<>
                        {position?.attributesExpanded
                          .filter(a => a.category === AttributeCategory.ExpectedContractLength)
                          .map(a =>
                            <Typography key={a.id} sx={{color: 'text.primary'}}>{a.attribute}</Typography>)}
                      </>}
            />
        }

        <TableRow image={`/img/teenyicons/hourglass.svg`}
                  title={'Hours / week:'}
                  content={<Typography sx={{color: 'text.primary'}} >{formatHoursPerWeek(position!!)}</Typography>}
        />

        <TableRow image={`/img/teenyicons/money.svg`}
                  title={'Salary range:'}
                  content={<Typography sx={{color: 'text.primary'}} >{showRange(position?.salaryRangeMin, position?.salaryRangeMax, undefined, undefined, toCurrency)} {!!position?.salaryRangeType && _.startCase(_.toLower(position?.salaryRangeType))}</Typography>}
        />

        <TableRow image={`/img/teenyicons/money.svg`}
                  title={'Commission or bonus:'}
                  content={<Typography sx={{color: 'text.primary'}} >{position?.commissionBonus ? position?.commissionBonus : "—"}</Typography>}
        />

        <TableRow image={`/img/teenyicons/appointments-gray.svg`}
                  title={'Date of start:'}
                  content={<Typography sx={{color: 'text.primary'}} >{position?.preferableStartDate ? DateTime.fromFormat(position?.preferableStartDate, 'yyyy-LL-dd').toFormat('LLL dd, yyyy') : ''}</Typography>}
        />
      </Box>
    </Stack>
  </Box>
)

function formatHoursPerWeek(position: PositionOutput) {

  if(position.hoursPerWeekMin === position.hoursPerWeekMax || !position.hoursPerWeekMax) {
    return position.hoursPerWeekMin
  } else {
    return `${position.hoursPerWeekMin} - ${position.hoursPerWeekMax}`
  }
}