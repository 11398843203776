import {Box, Container, Skeleton, Stack} from "@mui/material";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import CandidateCarousel from "../../company/pages/Candidates/ui/CandidateCarousel";
import React, {useEffect} from "react";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../../domain/matching_process/context/RescheduleMeetingClickHandlerContextProvider";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {withSuspense} from "../../shared";
import {useCandidateStore} from "./lib/useCandidateStore";
import {usePositionMatchStore} from "../../domain/matching_process";

function CandidateCarouselLayoutInternal() {

  const {positionId, positionMatchId} = useParams()
  const navigate = useNavigate()
  const store = usePositionMatchStore()

  const {fetchData, store: {candidates, position}} = useCandidateStore(positionId!!)

  useEffect(() => {
    fetchData()
  }, []);

  function handleCandidateSelect(index: number) {
    const positionMatchId = candidates!![index].id
    navigate(`/company/candidates/${positionId}/${positionMatchId}`)
  }

  useEffect(() => {
    if(!positionMatchId && !!candidates) {
      console.log('Redirect to first candidate')
      navigate(`/company/candidates/${positionId}/${candidates![0].id}`)
    }
  }, [positionMatchId, candidates]);

  useEffect(() => {
    store.update({positionId: positionId!, positionMatchId: positionMatchId!})
  }, [positionId, positionMatchId]);
  if(!candidates) {
    return null;
  }
  return <RescheduleMeetingClickHandlerContextProvider>
    <Box>
      <Box sx={{background: 'white', borderBottom: '1px solid #ECEFEF'}}>
        <Container sx={{paddingBottom: 4}} maxWidth={'lg'}>
          <Box sx={{marginY: 2}}>
            <Breadcrumbs crumbs={[{title: 'Roles', link: '../positions'}, {
              title: position?.title || 'Loading...',
              link: '../position/' + positionId
            }, {title: 'Candidates'}]}/>
          </Box>
          <CandidateCarousel candidates={candidates}
                             onCardClick={handleCandidateSelect}
                             active={positionMatchId}/>
        </Container>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  </RescheduleMeetingClickHandlerContextProvider>
}

export const CandidateCarouselLayout = withSuspense(CandidateCarouselLayoutInternal, <ComponentSkeleton />)

function ComponentSkeleton() {
  return <Stack spacing={3}>
    <Skeleton variant={"rounded"} width={250} height={40}/>
    <Stack direction={"row"} spacing={2}>
      <Skeleton variant={"rounded"} width={250} height={120}/>
      <Skeleton variant={"rounded"} width={250} height={120}/>
      <Skeleton variant={"rounded"} width={250} height={120}/>
      <Skeleton variant={"rounded"} width={250} height={120}/>
    </Stack>
  </Stack>


}