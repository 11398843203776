import {useNavigate, useParams} from "react-router-dom";
import React, {
  useContext,
  useEffect
} from "react";
import {Box, Button, Stack} from "@mui/material";
import ActionsMenu from "./ui/ActionsMenu";
import PublishMenu from "./ui/PublishMenu";
import useMeetingsByPositionId from "./hooks/useMeetingsByPositionId";
import {usePositionByIdApi} from "../../../../shared/hooks/usePositionByIdApi";
import {PositionStatus} from "../../../../api/sdl";
import {usePositionStatusApi} from "./hooks/usePositionStatusApi";
import {useIsMobile, withSuspense} from "../../../../shared";
import CompanyPositionBottomNav from "./ui/CompanyPositionBottomNav";
import {
  PositionViewJobDetails
} from "../../../../feature";

import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import {ScrollContext} from "../../../../layout/context/ScrollContext";
import {
  MobileCompanyPositionMeetings
} from "../../feature/mobile-company-position-meetings/MobileCompanyPositionMeetings";
import {InternalProps} from "../../feature/position-view/lib/types";
import PositionView from "../../feature/position-view/PositionView";
import PositionForm from "../../feature/position-form/PositionForm";
import {PositionEditingButtons} from "../../feature/position-view/ui/PositionEditingButtons";
import {PositionStatusView} from "../../feature/position-view/ui/PositionStatusView";
import {PositionViewCurrentExperience} from "../../feature/position-view/ui/PositionViewCurrentExperience";


function PositionPage() {
  const {id: positionId} = useParams();
  const isMobile = useIsMobile()

  const navigate = useNavigate();
  const {position, refetch} = usePositionByIdApi(positionId!!);
  const meetings = useMeetingsByPositionId(positionId!!);
  const [editMode, setEditModeInternal] = React.useState(!positionId);
  const {scrollTop} = useContext(ScrollContext)

  const nextInterview = React.useMemo(() => {
    return meetings[0];
  }, [meetings])

  const setEditMode = (v: boolean) => {
    setEditModeInternal(v)
    scrollTop()
  }

  return <Box>
      {!isMobile &&
          <Desktop {...{position, positionId, navigate, nextInterview, refetch, editMode, setEditMode}} />
      }

      {isMobile &&
          <Mobile {...{position, positionId, navigate, nextInterview, refetch, editMode, setEditMode}} />
      }
  </Box>;
}

function Desktop({position, navigate, positionId, refetch, nextInterview, editMode, setEditMode}: InternalProps) {

  const statusApi = usePositionStatusApi({
    refresh: () => {
      refetch({id: positionId})
    },

    positionId: positionId
  });


  return <>
    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 3}}>
      <Button variant={'text'} onClick={() => navigate('../positions')}>Current roles</Button>
      {!editMode &&
          <Box sx={{display: 'flex', gap: '12px'}}>
            {positionId && <PublishMenu positionId={positionId} positionStatus={position?.status} api={statusApi}/>}
            {positionId && position?.status !== PositionStatus.Open &&
                <Button onClick={() => {
                  statusApi.publish()
                }} variant={'contained'} data-test={"publish-rolw-button"}>Publish</Button>}
            {positionId && <ActionsMenu positionId={positionId}/>}
            {!editMode &&
                <Button disabled={!position} variant={'outlined'} onClick={() => setEditMode(true)}>Edit role</Button>}
          </Box>
      }
    </Box>
    {!editMode && <PositionView position={position} nextInterview={nextInterview}/>}
    {!!editMode && <PositionForm onSubmit={(posId) => {
      refetch({id: posId});
      navigate('../position/' + posId, {replace: true});
      setEditMode(false)
    }}
                                 id={positionId}
                                 onBackClick={() => {
                                   setEditMode(false)
                                   if (!positionId) {
                                     navigate('../positions', {replace: true})
                                   }
                                 }}/>}
  </>
}

function Mobile(props: InternalProps) {

  const {position, navigate, positionId, refetch, nextInterview, setEditMode, editMode} = props
  const [tabIndex, setTabIndex] = React.useState(0);
  const {scrollTop} = useContext(ScrollContext)

  useEffect(() => {
    if (tabIndex === 2) {
      navigate(`../candidates/${positionId}`)
    }
  }, [tabIndex])

  const changeTab = (index: number) => {
    setTabIndex(index)
    scrollTop()
  }

  const MobileBreadcrumbs = () => (<Box sx={{marginY: 0}}>
    <Breadcrumbs crumbs={[
      {title: 'Roles', link: '../positions'},
      {title: position?.title}]}/>
  </Box>)

  return <>
    {!editMode && <>
      {tabIndex === 0 && <Stack direction={"column"} spacing={2}>

          <MobileBreadcrumbs/>
          {!editMode && <PositionEditingButtons {...props} />}
          <PositionStatusView position={position}/>
          <PositionViewJobDetails position={position}/>
          <PositionViewCurrentExperience position={position}/>

      </Stack>}
      {tabIndex === 1 && <Stack direction={"column"} spacing={2}>
          <MobileBreadcrumbs/>
          <PositionStatusView position={position}/>
          <Box sx={{width: 1, '& p': {lineHeight: 1.5}}} dangerouslySetInnerHTML={{__html: position?.description || ''}} />
      </Stack>
      }

      {tabIndex === 3 && <Stack direction={"column"} spacing={2}>
          <MobileBreadcrumbs/>
          <MobileCompanyPositionMeetings positionId={positionId!} />
      </Stack>}

      <CompanyPositionBottomNav index={tabIndex} onChange={changeTab}/>
    </>}

    {!!editMode && <PositionForm onSubmit={(posId) => {
      refetch({id: posId});
      navigate('../position/' + posId, {replace: true});
      setEditMode(false)
    }}
                                 id={positionId}
                                 onBackClick={() => {
                                   setEditMode(false)
                                   if (!positionId) {
                                     navigate('../positions', {replace: true})
                                   }
                                 }}/>}
  </>
}

export default withSuspense(PositionPage)
