import React, {PropsWithChildren} from 'react';
import {Box, styled} from "@mui/material";
import {MeetingSlot} from "../../../entity/meeting/ui/MeetingSlot";
import {MeetingSlotCoords} from "../lib/types";

interface CalendarCellProps {
  text: string,
  filled: boolean,
  time?: string,
  meeting?: MeetingSlotCoords,
  isSelected: boolean,
}

export function CalendarCell(props: PropsWithChildren<CalendarCellProps>) {

  return <Box sx={{
    height: '100%',
  }} className={'noselect'}>
    <CalendarSlotContent {...props} />
  </Box>
}

function CalendarSlotContent(props: CalendarCellProps) {
  const CellComponent = props.isSelected ? SelectedCell : FilledCell

  if(props.meeting) {
    const displayName = props.meeting.slot.properties.find(p => p.key === 'contractorName')?.value ||
      props.meeting.slot.properties.find(p => p.key === 'companyName')?.value;

    return <CellComponent data-test='meeting-slot' sx={{
      border: `2px solid ${props.isSelected ? 'red' : '#3075B8'}`,
    }}>
      <MeetingSlot timeStr={props.time}
                   contractorName={displayName}
                   positionTitle={props.meeting.slot.properties.find(p => p.key === 'positionTitle')!.value}
                   positionId={props.meeting.slot.properties.find(p => p.key === 'positionId')!.value}
                   meetingId={props.meeting.slot.properties.find(p => p.key === 'meetingId')!.value}
                   positionMatchId={props.meeting.slot.properties.find(p => p.key === 'positionMatchId')?.value}/>
    </CellComponent>
  }

  if(props.filled) {
    return <CellComponent data-test='available-slot'  sx={{
    }}>{props.time}</CellComponent>;
  }

  return <Cell>{props.time}</Cell>;
}

const Cell = styled(Box)({
  height: '100%',
  width: 130,
  maxWidth: 130,
  backgroundColor: 'white',
  alignContent:'center',
  border: '2px solid #FFFFFF',
  fontSize: 13,
  flex: '1',
  color: 'rgba(47, 47, 47, 0.40)',
  borderRadius: 8,
})

const FilledCell = styled(Cell)({
  border: '2px solid #37C9EF',
  cursor: "pointer",
  '&:hover': {backgroundColor: 'rgba(48,117,184,0.2)!important'},
  color: '#2F2F2F',
})

const SelectedCell = styled(FilledCell)({
  border: '2px solid red',
  cursor: "pointer",
  '&:hover': {backgroundColor: 'rgba(48,117,184,0.2)!important'},
})