import {Navigate, Outlet} from 'react-router-dom'
import React, {useCallback, useContext, useEffect} from "react";
import {KeycloakContext} from "../../keycloak";
import {ProfileContextProvider} from "../../shared/ProfileContext";
import {AccessDeniedError} from "./AccessDeniedError";
import {useLocation, useNavigate} from "react-router-dom";

export function PrivateRouteUpdated() {
  const {initialized, keycloak, authenticated} = useContext(KeycloakContext);
  const location = useLocation()
  if (initialized) {
    if (authenticated) {
      return <ProfileContextProvider>
        <Outlet/>
      </ProfileContextProvider>
    } else {
      if(location.pathname === "/contractor" || location.pathname.startsWith("/contractor?")) {

        return <Navigate to={"/"} replace={true} />

      } else {

        const url = keycloak.createLoginUrl({redirectUri: document.location.href})
        window.location.href = url
      }
      // return <Navigate to={"/"} replace={true} />
      // document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})
    }
  }

  return null
}

