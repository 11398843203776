import {Box, Skeleton, Stack, Typography} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {GuestPerson, MeetingDuration} from "../../../../api/sdl";
import {DateTime} from "luxon";
import {AppointmentCalendar} from "./ui/AppointmentCalendar";
import {MeetingList, MeetingSetupForm} from "../../../../entity";
import {usePositionMatchCalendarApi} from "../../api/usePositionMatchCalendarApi";
import {useScheduleMeetingApi} from "../../api/useScheduleMeetingApi";
import {AvailabilityRequestButton} from "../availability-request-button/AvailabilityRequestButton";
import {usePositionMatchApi} from "../../api/usePositionMatchApi";
import {useIsMobile} from "../../../../shared";

interface Props {
  positionMatchId: string,
  onMeetingScheduled: () => void,
  onScheduleClick: () => void,
  meetingId?: string,
  // meeting?: MeetingListItem,
  meetingListTitle: string,
  slots?: {
    header?: (displayName: string) => JSX.Element,
  }
}

export function ScheduleMeeting(props: Props) {

  const isMobile = useIsMobile()
  const {onMeetingScheduled, positionMatchId, onScheduleClick, meetingListTitle, meetingId} = props;
  const {meetings, refetch: refetchMeetings, loading: meetingsLoading, meeting, positionMatch} = usePositionMatchApi({positionMatchId, meetingId})

  console.log('positionMatch?.displayName', positionMatch?.displayName)
  const {schedule, loading: formLoading} = useScheduleMeetingApi(positionMatchId, async () => {
    await refetchAll();
    onMeetingScheduled();
  }, meeting?.id)

  const [meetingDate, setMeetingDate] = React.useState<DateTime | undefined>();
  const formRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (meeting?.meetingScheduledOn) {
      setStartDate(meeting.meetingScheduledOn.startOf('week'));
      setMeetingDate(meeting.meetingScheduledOn)
    }
  }, [meeting]);

  useEffect(() => {
    if(meeting) {
      setMeetingDate(meeting.meetingScheduledOn)
    }

  }, [meeting?.id, meeting?.meetingScheduledOn]);

  const [startDate, setStartDate] = React.useState(DateTime.now().startOf('week'));
  const {
    slots,
    loading: slotsLoading,
    refetch: refetchCalendarSlots
  } = usePositionMatchCalendarApi(startDate, positionMatchId)

  const refetchAll = async () => {
    await refetchCalendarSlots();
    await refetchMeetings();
  }



  const handleScheduleClick = async (guests: GuestPerson[], duration: MeetingDuration) => {
    if (meetingDate) {
      await schedule(guests, meetingDate.toISO()!, duration);
      await refetchAll();
    }
  }

  const onCancelMeeting = async (meetingId: string) => {
    await refetchAll();
    setMeetingDate(undefined)
  }

  const scrollToForm = () => {
    formRef?.current?.scrollIntoView({behavior: "smooth"})
  }

  const contractorHeader = useMemo(() => {
    if(props.slots?.header) {
      return props.slots!.header(positionMatch?.displayName || "...")
    } else {
      return null
    }
  }, [props.slots?.header, positionMatch?.displayName]);

  return <Box sx={{display: 'flex', gap: 3, flexDirection: {xs: 'column', md: 'row'}}}>
    <Box>
      {contractorHeader}

      <AppointmentCalendar onStartDateChange={setStartDate}
                           loading={slotsLoading}
                           startDate={startDate}
                           selectedDate={meetingDate}
                           slots={slots}
                           onClick={(v) => {
                             if(isMobile) {
                               scrollToForm()
                             }
                             setMeetingDate(v)
                           }}/>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flexShrink: 0, width: {md:'330px', sm: '100%' }, marginTop: {xs: 0, md: '50px'}}}>

      {formLoading  && <MeetingFormSkeleton />}

      {meetingDate && !formLoading && !meetingsLoading && (
        <Box ref={formRef}>
          <MeetingSetupForm
            duration={meeting?.duration || MeetingDuration.Minutes_60}
            onCancel={() => setMeetingDate(undefined)}
            guests={meeting?.guests}
            onScheduled={(guests, duration) => {
              handleScheduleClick(guests, duration)
            }}
            meetingDate={meetingDate!!}
          />
        </Box>
      )}

      {!meetingDate && <Box>
          <Box sx={{display: 'flex', height: '150px', alignItems: 'center', flexDirection: "column", justifyContent: "center"}}>
              <Typography sx={{textAlign: 'center'}}>Please, select an available time slot</Typography>
          </Box>
          <Box sx={{display: 'flex', minHeight: "150px", justifyContent: "center"}}>
              <AvailabilityRequestButton positionMatchId={positionMatchId}/>
          </Box>
      </Box>}

      {(formLoading || meetingsLoading) && <MeetingSkeleton />}

      {!meetingsLoading && !formLoading && meetings &&
          <MeetingList meetings={meetings}
                       onScheduleClick={onScheduleClick}
                       onCancelMeeting={onCancelMeeting}
                       positionMatchId={positionMatchId}
                       showNextMeetingButton={false}
                       title={meetingListTitle}/>
      }

    </Box>
  </Box>
}

function MeetingSkeleton() {
  return <Stack spacing={2}>
    <Skeleton variant="rounded"  height={120} />
    <Skeleton variant="rounded"  height={120} />
    <Skeleton variant="rounded"  height={120} />
  </Stack>
}

function MeetingFormSkeleton() {
  return <Stack spacing={1}>
    <Skeleton variant="rounded"  height={175} />
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rounded"  height={40} width={"100%"} />
      <Skeleton variant="rounded"  height={40} width={"100%"}/>
    </Stack>
  </Stack>
}