import {create, useStore} from "zustand/react";
import {PositionCandidatesQuery, usePositionCandidatesSuspenseQuery} from "../../../api/sdl";
import {useEffect} from "react";
import {skipToken} from "@apollo/client";
import {CandidateListApiData} from "../../../company/pages/Candidates/lib/types";

export const candidateStore = create<{
  position: PositionCandidatesQuery["positionById"] | undefined,
  candidates: CandidateListApiData[] | undefined,
  updateIt: (position: PositionCandidatesQuery["positionById"] | undefined, candidates: CandidateListApiData[] | undefined) => void}>

((set) => ({
  candidates: undefined,
  position: undefined,
  updateIt: (position: any, candidates: any) => set({
    candidates, position
  }),
}));

export const useCandidateStore = (positionId: string) => {

  const {data, refetch} = usePositionCandidatesSuspenseQuery(skipToken);

  const store = useStore(candidateStore)

  useEffect(() => {
    store.updateIt(data?.positionById, data?.candidatesByPosition as CandidateListApiData[])
  }, [data]);

  const fetchData = async () => {
    const res = await refetch({positionId: positionId})
    store.updateIt(res?.data?.positionById, res?.data?.candidatesByPosition as CandidateListApiData[])
  }

  return {
    fetchData,
    store
  }
}