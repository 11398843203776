import {
  useArchivePositionMutation,
  usePublishPositionMutation,
  useUnPublishPositionMutation
} from "../../../../../api/sdl";
import {useSnackbar} from "notistack";

interface Props {
  refresh: () => void,
  positionId?: string,
}
export interface PositionStatusApiCalls {
  unpublish: () => void,
  archive: () => void,
  publish: () => void,
}

export function usePositionStatusApi({refresh, positionId}: Props): PositionStatusApiCalls {

  const { enqueueSnackbar } = useSnackbar();

  const [publish, {loading: publishLoading}] = usePublishPositionMutation({
    variables: {positionId},
    onCompleted: () => {
      enqueueSnackbar('Role was published successfully', {variant: 'success'});
      refresh();
    },
    onError: err => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  })
  const [unpublish, {loading: unpublishLoading}] = useUnPublishPositionMutation({
    variables: {positionId},
    onCompleted: () => {
      enqueueSnackbar('Role was unpublished successfully', {variant: 'info'});
      refresh();
    },
    onError: err => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  })
  const [archive, {loading: archiveLoading}] = useArchivePositionMutation({
    variables: {positionId},
    onCompleted: () => {
      enqueueSnackbar('Role was archived successfully', {variant: 'info'});
      refresh();
    },
    onError: err => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  })

  return {
    archive,
    publish,
    unpublish
  }
}