import {useNavigate, useParams} from "react-router-dom";
import {withSuspense} from "../../shared";
import React from "react";
import {useCandidateStore} from "../candidate-carousel-layout/lib/useCandidateStore";
import {CandidateApproval} from "../../domain/matching_process/feature/candidate-approval/CandidateApproval";
enum Mode {Preview, Approvement}

function CandidateCardScheduleInternal() {
  const navigate = useNavigate()
  const {positionMatchId, positionId} = useParams()
  const {fetchData} = useCandidateStore(positionId!!)

  function setMode(mode: Mode) {}

  const onBackClick = () => {
    navigate(`../${positionMatchId}`)
    fetchData();
  }

  return <>
    <CandidateApproval positionMatchId={positionMatchId!}
                       onScheduleClick={() => setMode(Mode.Approvement)}
                       onBackClick={onBackClick}
                       onScheduled={onBackClick}/>
  </>
}

export const CandidateCardSchedule = withSuspense(CandidateCardScheduleInternal)
