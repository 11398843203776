import {create} from 'zustand';
import {useStore} from "zustand/react";

type PositionMatchStoreType = {
  data?: {
    positionId: string, // Initial state
    positionMatchId: string, // Initial state
  }
  update: (value: NonNullable<PositionMatchStoreType["data"]>) => void,
}

const UsePositionMatchStore = create<PositionMatchStoreType>((set) => ({
  data: undefined,
  update: (value: NonNullable<PositionMatchStoreType["data"]>) => set({data: value}),
}));

export const usePositionMatchStore = () => {
  return useStore(UsePositionMatchStore)
}
