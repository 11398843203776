import {DateTime} from "luxon";
import {MeetingDuration} from "../../../api/sdl";

export function candidateLink(isAdmin: boolean, item: {positionId: string, positionMatchId: string, companyId?: string}) {
  if(isAdmin) {
    // /company/:id/position/:positionId?/:contractorId?
    return `/admin/company/${item.companyId}/position/${item.positionId}/${item.positionMatchId}`
  } else {
    // /company/candidates/:id/:candidateId?
    return `/company/candidates/${item.positionId}/${item.positionMatchId}`
  }
}

export function scheduleMeetingLink(item: {positionId: string, positionMatchId: string}) {
  return `../candidates/${item.positionId}/${item.positionMatchId}/schedule`
}

export function rescheduleLink(isAdmin: boolean, item: {positionId: string, positionMatchId: string, meetingId: string}) {

  if(isAdmin) {
    return `/admin/reschedule/${item.positionMatchId}/meeting/${item.meetingId}`
  } else {
    return `/company/candidates/${item.positionId}/${item.positionMatchId}/reschedule/${item.meetingId}`
  }
}

export function meetingSortFunc(a: {meetingScheduledOn: DateTime}, b: {meetingScheduledOn: DateTime}, current?: DateTime) {
  let aDate: number, bDate: number;

  if (current) {
    aDate = a.meetingScheduledOn.diff(current).milliseconds;
    bDate = b.meetingScheduledOn.diff(current).milliseconds;
  } else {
    aDate = a.meetingScheduledOn.diffNow().milliseconds;
    bDate = b.meetingScheduledOn.diffNow().milliseconds;
  }
  if (aDate === bDate) {
    return 0
  }

  if (aDate > 0 && bDate > 0) {
    // asc for future meetings
    return aDate - bDate > 0 ? 1 : -1;
  } else if (aDate < 0 && bDate < 0) {
    // desc for past meetings
    return aDate - bDate > 0 ? -1 : 1;
  } else {
    return aDate - bDate > 0 ? -1 : 1;
  }
}

export const isDateInFuture = (date: DateTime) => (
  date.diffNow().milliseconds > 0
)

export const durationToMinutes = (duration: MeetingDuration) => {

  switch (duration) {
    case MeetingDuration.Minutes_30: return 30;
    case MeetingDuration.Minutes_45: return 45;
    case MeetingDuration.Minutes_60: return 60;
  }
}

export const durationToString = (duration: MeetingDuration) => {

  switch (duration) {
    case MeetingDuration.Minutes_60: return '1 hour';
    case MeetingDuration.Minutes_30: return '30 minutes';
    case MeetingDuration.Minutes_45: return '45 minutes';

  }
}