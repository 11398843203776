import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useContext, useEffect} from "react";
import {KeycloakContext} from "../keycloak";
import {DefaultRedirects} from "../domain/routing/DefaultRedirects";
import {ScrollContext} from "./context/ScrollContext";

export function AppRoot() {

  const {authenticated, keycloak} = useContext(KeycloakContext)
  const location = useLocation()
  const {scrollTop} = useContext(ScrollContext)

  useEffect(() => {
    scrollTop()
  }, [location.pathname])

  if(location.pathname === "/") {
    if(authenticated === true) {
      return <DefaultRedirects keycloak={keycloak} />
    } else if(authenticated === false) {

      return <Navigate to={"/contractor-onboarding"} />
    }
  }

  return <>
    <Outlet/>
  </>
}