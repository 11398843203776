import {
  Autocomplete,
  Box,
  Button, Checkbox, CircularProgress,
  FormControl, FormControlLabel,
  FormHelperText,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import React, {FunctionComponent, lazy, useState} from "react";
import {
  AttributeCategory,
  PositionLocation,
  useContractorAttributesByCategoryQuery,
  usePositionByIdLazyQuery,
  useSavePositionMutation,
  ContractorAttribute,
} from "../../../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import {handleCollectionChange, withSuspense} from "../../../../shared";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {DateTime} from "luxon";
import useIsAdmin from "../../../../shared/hooks/useIsAdmin";
import ImproveRoleDescriptionModal from "../../../../feature/improve-role-description-modal/ImproveRoleDescriptionModal";
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import {schemaFields} from "./lib/schemaFields";
import {LocationFormSection} from "./ui/LocationFormSection";
import {SalaryField} from "./ui/SalaryField";
import {PositionFormApiData} from "./lib/types";
import {WorkingHoursField} from "./ui/WorkingHoursField";
import {convertToApi} from "./lib/utils";

const TextEditor = withSuspense(lazy(() => import("src/feature/text-editor/TextEditor")))


type PositionFormProps = {
  id?: string,
  isAdmin?: boolean,
  companyId?: string,
  companyName?: string,
  onSubmit: (positionId: string) => void,
  onBackClick: () => void,
  roles?: ContractorAttribute[],
  specialities?: ContractorAttribute[],
  apiData?: PositionFormApiData,
  saveLoading?: boolean,
  expectedContractLengths?: ContractorAttribute[],
}

export const withPositionFormDataProvider = (Form: FunctionComponent<PositionFormProps>) => (props: PositionFormProps) => {
  const [save, {loading: saveLoading}] = useSavePositionMutation();
  const [getPosition, {data, loading}] = usePositionByIdLazyQuery({fetchPolicy: 'no-cache'});
  const [initial, setInitial] = React.useState();

  const rolesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.RoleCategory}});
  const roles = React.useMemo(() => {
    return rolesData.data?.contractorAttributesByCategory || [];
  }, [rolesData.data]);

  const specialitiesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.CallPointSpeciality}});
  const specialities = React.useMemo(() => {
    return specialitiesData.data?.contractorAttributesByCategory || [];
  }, [specialitiesData.data]);

  const expectedContractLengthData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.ExpectedContractLength}});
  const expectedContractLengths = React.useMemo(() => {
    return expectedContractLengthData.data?.contractorAttributesByCategory || [];
  }, [expectedContractLengthData.data]);

  const isAdmin = useIsAdmin();

  React.useEffect(() => {
    if (props.id) {
      getPosition({variables: {id: props.id}});
    }
  }, [props.id])

  React.useEffect(() => {
    if (data?.positionById) {
      setInitial(data.positionById as any)
    }
  }, [data]);

  const onSubmit = (payload: any) => {
    save({variables: {
        id: props.id,
        companyId: props.companyId,
        payload
      }
    }).then(res => props.onSubmit(res.data!.savePosition.id))
  }

  if (loading) {
    return <Box sx={{display: 'flex', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, alignItems: 'center', justifyContent: 'center', background: '#ffffff8c', position: 'absolute'}}>
      <CircularProgress size="15" />
    </Box>
  }

  return <Form saveLoading={saveLoading} isAdmin={isAdmin} apiData={initial} roles={roles} specialities={specialities} expectedContractLengths={expectedContractLengths} {...props} onSubmit={onSubmit}/>
}

export const PositionFormInternal = (props: PositionFormProps) => {
  const locationRef = React.useRef(null);
  const [improveModalOpen, setImproveModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [isSalaryExpanded, setSalaryExpanded] = useState<boolean>(false);
  const [isWorkingHoursExpanded, setWorkingHoursExpanded] = useState<boolean>(false);
  const roles = React.useMemo(() => {
    return props.roles || []
  }, [props.roles])

  const expectedContractLengths = React.useMemo(() => {
    return props.expectedContractLengths || []
  }, [props.expectedContractLengths])

  const specialities = React.useMemo(() => {
    return props.specialities || []
  }, [props.specialities])

  const formik = useFormik({
    initialValues: {
      roles: [] as string[],
      specialities: [] as string[],
      expectedContractLengths: [] as string[],
      title: '',
      description: '',
      commissionBonus: '',
      locations: [] as PositionLocation[],
      salaryRangeType: '',
      preferableStartDate: new Date(),
      salaryRangeMax: '',
      salaryRangeMin: '',
      hoursPerWeekMin: '',
      hoursPerWeekMax: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {

      if (formik.isValid) {
        const payload = convertToApi(values, (locationRef.current as any)?.getLocations())
        props.onSubmit(payload)
      }
    },
    validateOnChange: true
  });

 React.useEffect(() => {

    if (props.apiData) {
      formik.setValues({
        roles: props.apiData.attributesExpanded.filter((a:any) => a.category === AttributeCategory.RoleCategory).map((i:any) => i.id) || [] as string[],
        specialities: props.apiData.attributesExpanded.filter((a:any) => a.category === AttributeCategory.CallPointSpeciality).map((i:any) => i.id) || [] as string[],
        expectedContractLengths: props.apiData.attributesExpanded.filter((a:any) => a.category === AttributeCategory.ExpectedContractLength).map((i:any) => i.id) || [] as string[],
        title: props.apiData.title || '',
        description: props.apiData.description || '',
        commissionBonus: props.apiData.commissionBonus || '',
        salaryRangeType: props.apiData.salaryRangeType || '',
        hoursPerWeekMin: props.apiData.hoursPerWeekMin?.toString() || '',
        hoursPerWeekMax: props.apiData.hoursPerWeekMax?.toString() || '',
        preferableStartDate: DateTime.fromFormat(props.apiData.preferableStartDate, 'yyyy-LL-dd').toJSDate() || new Date(),
        salaryRangeMax: props.apiData.salaryRangeMax?.toString() || '',
        salaryRangeMin: props.apiData.salaryRangeMin?.toString() || '',
        locations: props.apiData.locations || [],
      })

      setSalaryExpanded(!!props.apiData.salaryRangeMax);
      setWorkingHoursExpanded(!!props.apiData.hoursPerWeekMax);

      formik.setFieldValue('salaryIsRange', !!props.apiData.salaryRangeMax)
      formik.setFieldValue('hoursAreRange', !!props.apiData.hoursPerWeekMax)

      // console.log('salaryIsRange', !!props.apiData.salaryRangeMax);
    }
  }, [props.apiData]);

  const onImproveModalClose = (result?: string) => {
    if (result) {
      formik.setFieldValue('description', result)
      enqueueSnackbar('Description copied', {variant: 'success'});
    }
    setImproveModalOpen(false);
  };

  const handleSalaryRangeClicked = () => {
    if(isSalaryExpanded) {
      formik.setFieldValue("salaryRangeMax", '');
    }
    setSalaryExpanded( !isSalaryExpanded);
    formik.setFieldValue('salaryIsRange', !isSalaryExpanded)
  };

  const handleWorkingHoursRangeClicked = () => {
    if(isWorkingHoursExpanded) {
      formik.setFieldValue("hoursPerWeekMax", '')
    }
    setWorkingHoursExpanded( !isWorkingHoursExpanded);
    formik.setFieldValue('hoursAreRange', !isWorkingHoursExpanded)
  }

  return <Box component={'form'} onSubmit={formik.handleSubmit}>
    {/*{JSON.stringify(formik.errors)}*/}
    <ImproveRoleDescriptionModal open={improveModalOpen}
                                 companyName={props.companyName}
                                 title={formik.values.title}
                                 message={formik.values.description}
                                 onClose={onImproveModalClose}/>
    <Typography variant={'h2'} sx={{mb: 3}}>{props.id ? 'Edit' : 'Create new'} role</Typography>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1, position: 'relative'}}>
      <FormControl>
        <FormLabel>Position title</FormLabel>
        <TextField placeholder="Enter title"
               name="title"
               data-test="title"
               value={formik.values.title}
               onChange={formik.handleChange}
               error={formik.touched.title && Boolean(formik.errors.title)}
        />
        {(formik.touched.title && formik.errors.title) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.title}</FormHelperText>}
      </FormControl>

      <FormControl>
        <FormLabel>Position description</FormLabel>
        <TextEditor value={formik.values.description} onChange={v => formik.setFieldValue('description', v)} data-test="description" />
        {(formik.touched.description && formik.errors.description) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.description}</FormHelperText>}
      </FormControl>

      {props.isAdmin && <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => {setImproveModalOpen(true)}}>Improve with AI</Button>
      </Box>}

      <Box sx={{display: 'flex', gap: 2, width: 1, flexDirection: {xs: "column", md: "row"}}}>
        <FormControl sx={{ width: 1 }} error={formik.touched.specialities && !!formik.errors.specialities}>
          <FormLabel>
          {/*<FormLabel id="category-select-label" htmlFor="category-select">*/}
            Call point specialities
          </FormLabel>
          <Autocomplete
            multiple
            onChange={(event, newValue) => {
              formik.setFieldValue('specialities', newValue);
            }}
            placeholder="Select specialities"
            data-test='call-point-specialities'
            limitTags={2}
            value={formik.values.specialities}
            getOptionLabel={o => specialities.find(r => r.id === o)?.attribute || ''}
            options={specialities.map(a => a.id)}
            renderInput={(params) => <TextField {...params} />}
          />
          {(formik.touched.specialities && formik.errors.specialities) && <FormHelperText sx={{color: '#D3232F'}} data-test="specialities-error">{formik.errors.specialities}</FormHelperText>}
        </FormControl>

        <FormControl sx={{ width: 1 }} error={formik.touched.roles && !!formik.errors.roles}>
          <FormLabel >
            Role category
          </FormLabel>
          <Autocomplete
            multiple
            placeholder="Select category"
            data-test='role-category'
            options={roles.map(a => a.id)}
            value={formik.values.roles}
            onChange={(event, newValue) => {
              formik.setFieldValue('roles', newValue);
            }}
            getOptionLabel={o => roles.find(r => r.id === o)?.attribute || ''}
            renderInput={(params) => <TextField {...params} />}
          />
          {(formik.touched.roles && formik.errors.roles) && <FormHelperText sx={{color: '#D3232F'}} data-test="roles-error">{formik.errors.roles}</FormHelperText>}
        </FormControl>
      </Box>

      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <FormLabel sx={{mb: 1}}>
          Expected length of contract
        </FormLabel>
        <Typography variant={"body1"}>
          This is just an estimate and we understand that contracts may be shorter or longer if circumstances change.
        </Typography>
        <Box data-test='contract-length-checkboxes' sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: 1, gap: 2}}>
          {expectedContractLengths.map((type, i) => (
            <FormControlLabel
              key={type.id}
              control={(
              <Checkbox
                key={type.id}
                value={type.id}
                checked={formik.values.expectedContractLengths.includes(type.id)}
                onChange={(value) => formik.setFieldValue(
                  'expectedContractLengths',
                  handleCollectionChange<string>(type.id, formik.values.expectedContractLengths)
                )}
              />
            )}
                              label={type.attribute} />
          ))}
        </Box>
        {(formik.touched.expectedContractLengths && formik.errors.expectedContractLengths) && <FormHelperText sx={{color: '#D3232F', mt: 2}} data-test="expected-contract-lengths-error">{formik.errors.expectedContractLengths}</FormHelperText>}
      </Box>

      <LocationFormSection ref={locationRef} onChange={() => {}} locations={formik.values.locations} />
      {(formik.touched.locations && formik.errors.locations) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.locations}</FormHelperText>}

      <SalaryField formik={formik} rangeIsExpanded={isSalaryExpanded} onRangeToggleClicked={handleSalaryRangeClicked}/>

      <FormControl>
        <FormLabel>Commission or bonus</FormLabel>
        <TextField placeholder="Commission or bonus"
               data-test='commission-bonus'
               name="commissionBonus"
               value={formik.values.commissionBonus}
               onChange={formik.handleChange}
               error={formik.touched.commissionBonus && Boolean(formik.errors.commissionBonus)}
        />
        {(formik.touched.commissionBonus && formik.errors.commissionBonus) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.commissionBonus}</FormHelperText>}
      </FormControl>

      <WorkingHoursField rangeIsExpanded={isWorkingHoursExpanded} formik={formik} onRangeToggleClicked={handleWorkingHoursRangeClicked}/>


      <FormControl>
        <FormLabel>Preferable date of start</FormLabel>
        <DatePicker selected={formik.values.preferableStartDate}
                    customInput={<TextField placeholder="Select date"
                      error={formik.touched.preferableStartDate && Boolean(formik.errors.preferableStartDate)}
                    />}
                    minDate={new Date()}
                    onChange={(date: Date) => formik.setFieldValue('preferableStartDate', date)} />
        {(formik.touched.preferableStartDate && formik.errors.preferableStartDate) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.preferableStartDate}</FormHelperText>}
      </FormControl>


      <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between'}}>
        <Button disabled={props.saveLoading} color={'secondary'} variant={'outlined'} onClick={props.onBackClick}>Back</Button>
        <LoadingButton loading={props.saveLoading} data-test='create' variant={'contained'} onClick={() => {
          formik.setFieldValue('locations', (locationRef.current as any)?.getLocations() || [])
          setTimeout(() => formik.submitForm(), 100);
        }}>{props.id ? 'Save' : 'Create'}</LoadingButton>
      </Box>
    </Box>
  </Box>
}

export default withPositionFormDataProvider(PositionFormInternal);

