import {
  useFutureMeetingsSuspenseQuery, usePastMeetingsSuspenseQuery,
} from "../../../api/sdl";
import {MeetingDetailsItem} from "../lib/types";

export function useAllMeetingsApi(isAdmin: boolean, page: number, positionId?: string) {

  const {data: future, refetch: refetchFuture} =
    useFutureMeetingsSuspenseQuery({
        fetchPolicy: 'no-cache',
        variables: {positionId: positionId}
      }
    );

  const {data: past, refetch: refetchPast} = usePastMeetingsSuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {positionId, page: {page, size: 10}},
  });

  const pastMeetings = past?.pastMeetings.data as MeetingDetailsItem[] || []
  const hasNext = past?.pastMeetings?.hasNext || false
  const hasPrevious = past?.pastMeetings?.hasPrevious || false

  const futureMeetings = future?.futureMeetings as MeetingDetailsItem[] || []

  return {
    noData: !futureMeetings.length && !pastMeetings.length,
    futureMeetings,
    pastMeetings,
    hasPrevious,
    hasNext,
    loading: true,
    refetchMeetings: async () => {
      refetchFuture()
      refetchPast()
    },
  }
}