import {PositionMatchStatus} from "../../../../api/sdl";
import {Box, Card, Stack, Typography} from "@mui/material";
import React from "react";
import {MeetingShortApiData} from "../lib/types";
import {DateTime} from "luxon";

type Props = {
  salary: string,
  location: string,
  status: PositionMatchStatus,
  active: boolean,
  onClick: (e: any) => void,
  displayName: string,
  meetings: MeetingShortApiData[],
}

const CandidateCard = ({status, onClick, active, location, salary, displayName, meetings}: Props) => {

  return <Card onClick={onClick} sx={{minWidth: 242, p: 2, cursor: 'pointer', background: active ? '#F0F8FD' : '#fff'}}>
    <Stack spacing={1}>
      <Typography sx={{ fontWeight: 600, color: '#3075B8', whiteSpace: 'nowrap'}} data-test="candidate-name">{displayName}</Typography>
      <Box display={'flex'} alignItems={'center'}>
        <Box component={'img'} src={`/img/teenyicons/pin.svg`}  width={15} height={15} />
        <Typography variant={'body2'} sx={{ml: '6px', mr: '14px', whiteSpace: 'nowrap'}}>{location}</Typography>

        <Box component={'img'} src={`/img/teenyicons/money.svg`}  width={15} height={15} />
        <Typography variant={'body2'} sx={{ml: '6px', whiteSpace: 'nowrap'}}>{salary}</Typography>
      </Box>
      <Typography variant={'body2'} sx={{fontWeight: 600}}>{mapStatus(status)}</Typography>
      <MeetingLine meetings={meetings} />
    </Stack>
  </Card>
}

export default CandidateCard;

const mapStatus = (status: PositionMatchStatus): string => {
  switch (status) {
    case PositionMatchStatus.CompanyDeclined: return 'Rejected';
    case PositionMatchStatus.CompanyScheduledCall: return 'Interviewing';
    default: return 'For Review';
  }
}

const MeetingLine = ({meetings}: { meetings: MeetingShortApiData[]}) => {

  if(meetings.length === 0) return null;

  const futureMeetings = meetings
    .filter(m => DateTime.fromISO(m.meetingScheduledOn) > DateTime.now())
    .map(m => DateTime.fromISO(m.meetingScheduledOn))
    .sort((a, b) => a.diff(b).as('minutes'))

  const pastMeetings = meetings
    .filter(m => DateTime.fromISO(m.meetingScheduledOn) < DateTime.now())
    .map(m => DateTime.fromISO(m.meetingScheduledOn))
    .sort((a, b) => a.diff(b).as('minutes'))

  if(futureMeetings.length > 0) {
    return <Typography variant={'body2'} sx={{fontWeight: 600}}>Next interview: {futureMeetings[0].toFormat('LLL d, h:mm a')}</Typography>
  } else {
    return <Typography variant={'body2'} sx={{fontWeight: 400}}>Last interview: {pastMeetings[0].toFormat('LLL d, h:mm a')}</Typography>
  }
}