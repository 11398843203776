import {client} from "../../ApolloClient";
import {keycloak} from "../../keycloak";
import {gql} from "graphql-tag";

export const companyMenu = async () => {
  const notifications = client.query({
    query: gql`{myNotificationCounter}`,
    fetchPolicy: 'no-cache'
  })
  return {
    top: [
      {
        title: 'Roles',
        route: '/company/positions',
        icon: 'briefcase',
      },
      {
        title: 'Interviews',
        icon: 'appointments',
        route: '/company/interviews',
      },
      {
        title: 'Notifications',
        icon: 'bell',
        route: '/company/notifications',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'Contracts',
        icon: 'appointments',
        route: '/company/contracts',
      },
    ],
    bottom: [
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => keycloak.logout({redirectUri: document.location.origin})
      }
    ]
  }
}