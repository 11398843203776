import {hasKeycloakRole} from "../../shared";
import {adminMenu} from "./adminMenu";
import contractorMenu from "./contractorMenu";
import {companyMenu} from "./companyMenu";

export const unifiedMenu = async () => {

  if(hasKeycloakRole("ROLE_ADMIN")) {
    return await adminMenu()
  }

  if(hasKeycloakRole("ROLE_CONTRACTOR")) {
    return await contractorMenu()
  }

  if(hasKeycloakRole("ROLE_COMPANY")) {
    return await companyMenu()
  }

}