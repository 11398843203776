import {useFormik} from "formik";
import * as yup from "yup";
import {Box, Button, FormControl, FormHelperText, FormLabel, Stack} from "@mui/material";
import * as React from "react";
import {NoteValue} from "../lib/types";
import {withSuspense} from "../../../../../shared";
import {lazy} from "react";
const TextEditor = withSuspense(lazy(() => import("src/feature/text-editor/TextEditor")))

interface Props {
  initialValue: NoteValue
  onSubmit: (value: NoteValue) => void
  onCancel: () => void
}

export function NotesForm(props: Props) {

  const formik = useFormik({
    initialValues: {
      message: props.initialValue.message || '',
    },
    validationSchema: yup.object({
      message: yup.string()
    }),
    onSubmit: (values) => {
      formik.isValid && props.onSubmit({
        message: values.message,
        id: props.initialValue.id,
        reference: props.initialValue.reference,
      })
    },
    validateOnChange: true
  });

  return <Box component={'form'} flex={4} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
      <FormControl>
        <FormLabel>Note</FormLabel>
        <TextEditor value={formik.values.message} onChange={v => formik.setFieldValue('message', v)} />
        {(formik.touched.message && formik.errors.message) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.message}</FormHelperText>}
      </FormControl>

      <Stack sx={{mt: 3, display: 'flex', gap: 3, justifyContent: "space-between"}} direction={"row"} >
        <Button variant={'outlined'} color={'secondary'} onClick={props.onCancel}>Cancel</Button>
        <Button variant={'contained'} type="submit" data-test="notes-save-button">Save</Button>
      </Stack>
    </Box>
  </Box>
}