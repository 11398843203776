import {Outlet, useLocation} from "react-router-dom";
import Layout from "../shared/components/Layout";
import {hasKeycloakRole} from "../shared";
import {Container} from "@mui/material";
import {useContext, useEffect} from "react";
import {AccessDeniedError} from "../domain/routing/AccessDeniedError";
import {KeycloakContext} from "../keycloak";

interface Props {
  menu: any,
  showMenu: boolean,
}

function LayoutInternal({menu, showMenu}: Props) {
  const {authenticated} = useContext(KeycloakContext);
  const location = useLocation()

  useEffect(() => {

    if(authenticated === true) {
      if(location.pathname.startsWith("/admin") && !hasKeycloakRole("ROLE_ADMIN")) {
        throw new AccessDeniedError("Access denied to admin resource")
      }

      if(location.pathname.startsWith("/contractor") && !hasKeycloakRole("ROLE_CONTRACTOR")) {
        throw new AccessDeniedError("Access denied to contractor resource")
      }

      if(location.pathname.startsWith("/company") && !hasKeycloakRole("ROLE_COMPANY")) {
        throw new AccessDeniedError("Access denied to company resource")
      }
    }
  }, [location.pathname, authenticated])

  if(!showMenu) {
    return <Outlet />
  } else {
    return <>
      <Layout menu={menu}>
        <Container  sx={{my: 4}} maxWidth={"lg"}>
          <Outlet/>
        </Container>
      </Layout>
    </>
  }

}

export const LayoutWithMenu = LayoutInternal