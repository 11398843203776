import * as React from 'react';
import {BottomNav} from "../../shared";
import {useMemo} from "react";

export default function ContractorCardBottomNav({index, onChange}: {
  index: number,
  onChange: (value: number) => void,
}) {

  const items = useMemo(() => {
    const result = [
      {
        active: index === 0,
        icon: <Icon name={'clipboard'} active={index === 0} />,
        label: 'Summary'
      },
      {
        active: index === 1,
        icon: <Icon name={'text-document'} active={index === 1} />,
        label: 'Experience'
      },
      {
        active: index === 2,
        icon:  <Icon name={'play-circle'} active={index === 2} />,
        label: 'Videos'
      },
      {
        active: index === 3,
        icon:  <Icon name={'trophy'} active={index === 3} />,
        label: 'Awards'
      },
    ]

    return result;
  }, [index]);

  return <BottomNav items={items}
                  index={index}
                  onChange={onChange}/>
}

const Icon = ({name, active}: {name: string, active: boolean}) => (
  <img src={`/img/teenyicons/${name}${active ? '-blue' : ''}.svg`} width={20} height={20}/>
)
