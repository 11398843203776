import {Box, Button, Container, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {scheduleMeetingLink} from "../../../entity/meeting/lib/utils";
import {ArrowLeft} from "phosphor-react";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../context/RescheduleMeetingClickHandlerContextProvider";
import {usePositionMatchApi} from "../api/usePositionMatchApi";
import {ScheduleMeeting} from "../feature/schedule-meeting/ScheduleMeeting";
import {useNavigate, useParams} from "react-router-dom";
import {usePositionMatchStore} from "../feature/schedule-meeting/hooks/usePositionMatchStore";

type RouteParams = {
  positionMatchId: string; // Mandatory parameter
  meetingId?: string;       // Mandatory parameter
};


export default function ReschedulePage() {
  const navigate = useNavigate();
  const {positionMatchId, meetingId} = useParams<RouteParams>();

  // @ts-ignore
  const {positionMatch, meeting: currentMeeting } = usePositionMatchApi({positionMatchId, meetingId})
  const store = usePositionMatchStore()

  useEffect(() => {
    if(positionMatch) {
      store.update({positionMatchId: positionMatch.id, positionId: positionMatch.positionId})
    }
  }, [positionMatch]);

  const handleScheduleAgainClick = () => {
    navigate(scheduleMeetingLink({positionId: positionMatch!.positionId, positionMatchId: positionMatch!.id}))
  }

  const header = (name: string) => <Box sx={{my: 2}}>
    <Typography sx={{fontWeight: 700, fontSize: 18, my: 2}}>Reschedule interview with {name}</Typography>
    <Typography>Use this calendar to schedule an interview. Once you pick a time, you will be sent a calendar invite
      via email.</Typography>
  </Box>;

  if(!currentMeeting) {
    return null
  }

  return <RescheduleMeetingClickHandlerContextProvider>
    <Container sx={{my: 4}} maxWidth={'lg'}>
      <Box>
        <Button variant={'text'} startIcon={<ArrowLeft size={20} color="#3075B8" weight="regular"/>}
                onClick={() => {navigate(-1)}}>Back</Button>
      </Box>
     <ScheduleMeeting positionMatchId={positionMatchId!!}
                       meetingId={meetingId}
                       meetingListTitle={'Other interviews with this candidate'}
                       onScheduleClick={handleScheduleAgainClick}
                       onMeetingScheduled={() => {}}
                       slots={{ header }}
      />
    </Container>
  </RescheduleMeetingClickHandlerContextProvider>
}

