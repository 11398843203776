import {createContext, PropsWithChildren, useRef} from "react";
import {Box} from "@mui/material";

const defaultValue = {
  scrollTop: () => {}
}
export const ScrollContext = createContext(defaultValue)

export function ScrollContextProvider(props: PropsWithChildren<any>) {

  const topRef = useRef<HTMLElement | null>(null)
  const scrollTop = () => {
    topRef.current?.scrollIntoView({behavior: "smooth"})
  }
  return <ScrollContext.Provider value={{scrollTop}}>
    <Box ref={topRef}>
      {props.children}
    </Box>
  </ScrollContext.Provider>
}