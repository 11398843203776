import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Container, Typography} from "@mui/material";
import React, {useState} from "react";
import {PositionMatchStatus,} from "../../../../api/sdl";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import {expectedSalaryFromPositionMatch, withSuspense} from "../../../../shared";
import {ProfileContext} from "../../../../shared/ProfileContext";
import CandidateCarousel from "../../../../company/pages/Candidates/ui/CandidateCarousel";
import useRejectCandidateClick from "../../api/useRejectCandidateClick";
import useCandidatesByPositionApi from "../../api/useCandidatesByPositionApi";
import {usePositionByIdApi} from "../../../../shared/hooks/usePositionByIdApi";
import useCandidateHasContract from "../../api/useCandidateHasContract";
import {MeetingList} from "../../../../entity";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../../context/RescheduleMeetingClickHandlerContextProvider";
import {convertMeetingsFromApiData} from "../../api/usePositionMatchApi";
import {CandidateApproval} from "../../feature/candidate-approval/CandidateApproval";
import { ContractorCard } from "src/feature/contractor-card";
import {CandidatesPageSkeleton} from "./ui/CandidatesPageSkeleton";

enum Mode {Preview, Approvement}

export function CandidatesPage(props: {schedule?: boolean}) {
  const {positionId, positionMatchId} = useParams();
  const [profile] = React.useContext(ProfileContext);
  const [mode, setMode] = useState(props.schedule ? Mode.Approvement : Mode.Preview)
  const navigate = useNavigate();
  const {position} = usePositionByIdApi(positionId!!);
  const {candidates, refetch} = useCandidatesByPositionApi(positionId!!, positionMatchId);
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0);

  const currentCandidate = candidates[currentCandidateIndex];

  const meetings = currentCandidate?.meetings ? convertMeetingsFromApiData(currentCandidate!.meetings) : [];

  const hasContract = useCandidateHasContract(currentCandidate?.contractorId, profile?.company?.id);

  const onRejectClick = useRejectCandidateClick(refetch, currentCandidate?.id);

  const handleCandidateSelect = (newIndex: number) => {
    setCurrentCandidateIndex(newIndex);
    setMode(Mode.Preview);
  }

  const onCancelMeeting = async (meetingId: string) => {
    await refetch();
  }

  return <RescheduleMeetingClickHandlerContextProvider>
      <Box sx={{background: 'white', borderBottom: '1px solid #ECEFEF'}}>
        <Container sx={{paddingBottom: 4}} maxWidth={'lg'}>
          <Box sx={{marginY: 2}}>
            <Breadcrumbs crumbs={[{title: 'Roles', link: '../positions'}, {
              title: position?.title || 'Loading...',
              link: '../position/' + positionId
            }, {title: 'Candidates'}]}/>
          </Box>
          <CandidateCarousel candidates={candidates}
                             onCardClick={handleCandidateSelect}
                             active={currentCandidate?.id}/>
        </Container>
      </Box>
      <Box>
        {(!candidates?.length) && <Box sx={{
          width: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 80px)'
        }}>
            <Typography>No candidates yet</Typography>
        </Box>}

        {currentCandidate && <>
          {mode === Mode.Preview && (
            <ContractorCard profile={currentCandidate.contractorProfile}
                            firstName={currentCandidate.displayName}
                            lastName={undefined}
                            showSideStripe={false}
                            educations={currentCandidate.educations}
                            compositeAnswers={currentCandidate.compositeAnswers}
                            extraContent={<>
                              {(currentCandidate?.status !== PositionMatchStatus.CompanyScheduledCall && currentCandidate?.status !== PositionMatchStatus.CompanyDeclined) &&
                                  <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                                      <Button data-test='reject'
                                              variant={'outlined'}
                                              color={'warning'}
                                              sx={{width: '140px'}}
                                              onClick={onRejectClick}>
                                          Reject
                                      </Button>

                                      <Button data-test='approve'
                                              variant={'contained'}
                                              fullWidth
                                              onClick={() => setMode(Mode.Approvement)}>
                                          Schedule meeting
                                      </Button>
                                  </Box>}
                              {currentCandidate?.status === PositionMatchStatus.CompanyDeclined &&
                                  <Typography sx={{fontWeight: 600}}>Rejected</Typography>}

                              {(!!currentCandidate.expectedRate) && <Box sx={{
                                background: '#F0F8FD',
                                border: '1px solid #DDE1E3',
                                borderRadius: '10px',
                                p: '20px 24px'
                              }}>
                                  <Typography variant={"body1"} fontSize={16} fontWeight={600}>Salary Requirement:</Typography>
                                  <Typography variant={"body1"}
                                              sx={{paddingTop: 1}}>{expectedSalaryFromPositionMatch(currentCandidate)}</Typography>
                              </Box>}

                              {(currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall && !hasContract) && (
                                  <Box sx={{mb: 2}}>
                                    <Button data-test='hire'
                                            sx={{width: 1,}}
                                            onClick={() => navigate('/company/hire/' + positionId + '/' + currentCandidate?.id)}
                                            variant="contained">
                                      Hire Contractor
                                    </Button>
                                  </Box>
                              )}

                              <MeetingList meetings={meetings}
                                           onScheduleClick={() => setMode(Mode.Approvement)}
                                           onCancelMeeting={onCancelMeeting}
                                           showNextMeetingButton={currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall}
                                           positionMatchId={currentCandidate!.id}
                                           title={'Scheduled interviews with this candidate'} />
                            </>}
            />
          )}

          {mode === Mode.Approvement && (
            <CandidateApproval positionMatchId={currentCandidate!.id}
                               onScheduleClick={() => setMode(Mode.Approvement)}
                               onBackClick={() => setMode(Mode.Preview)}
                               onScheduled={() => {
                        refetch();
                        setMode(Mode.Preview);
                      }}/>
          )}
        </>}


      </Box>
  </RescheduleMeetingClickHandlerContextProvider>
}

export default withSuspense(CandidatesPage, <CandidatesPageSkeleton />)