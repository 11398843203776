import {Box, Button, IconButton, Typography} from "@mui/material";
import React from "react";
import {Pencil} from "phosphor-react";
import {DateTime} from "luxon";
import {useIsCompany} from "../../../../shared";
import {candidateLink} from "../../../../entity";
import {PositionStatusView} from "./ui/PositionStatusView";
import {PositionViewCurrentExperience} from "./ui/PositionViewCurrentExperience";
import {useNavigate} from "react-router-dom";
import {PositionFormApiData} from "../position-form/lib/types";
import {PositionViewJobDetails} from "../../../../feature";
import {MeetingDetailsOutput} from "../../../../api/sdl";

interface PositionViewProps {
  position: PositionFormApiData,
  onEditClick?: () => void,
  extraAction?: JSX.Element,
  onCandidatesClick?: () => void,
  nextInterview?: MeetingDetailsOutput,
}

const PositionView = ({position, onEditClick, onCandidatesClick, extraAction, nextInterview}: PositionViewProps) => {
  const navigate = useNavigate();

  const isCompany = useIsCompany()

  return <Box>
    <PositionStatusView position={position} />

    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography sx={{fontSize: 34, lineHeight: '48px', fontWeight: 600, mb: 1}}>{position?.title}</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
        {extraAction && <>{extraAction}</>}
        {onEditClick && <IconButton
          size="small"
          sx={{borderRadius: '50%'}}
          onClick={onEditClick}
        >
          <Pencil size={20} color="#37c9ef" weight="light" />
        </IconButton>}
      </Box>
    </Box>

    {isCompany && <Box data-test='box-candidates' sx={{display: 'flex', alignItems: 'center', gap: 3}}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'space-between', p: '16px 24px', borderRadius: '10px', background: '#F0F8FD', flex: 1}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 48, background: '#37C9EF', borderRadius: '24px'}}>
            <Box component={'img'} src={`/img/teenyicons/users-blue.svg`}  width={24} height={24} />
          </Box>
          <div>
            <Typography  sx={{fontWeight: 600, mb: .2}} data-test={"candidate-number-total"}>{position.numberOfCandidates}</Typography>
            <Typography >Candidates</Typography>
          </div>
          <div>
            <Typography  sx={{ fontWeight: 600, mb: .2}} data-test={"candidate-number-for-review"}>{position.candidatesForReview}</Typography>
            <Typography >For review</Typography>
          </div>
        </Box>

        {!!position.numberOfCandidates &&
            <Typography  onClick={() => navigate(`../candidates/${position!.id}`)}
                         sx={{
                           fontWeight: 600,
                           color: '#3075B8',
                           cursor: 'pointer',
                           textDecoration: 'underline'
                         }}>View candidates</Typography>}
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'space-between', p: '16px 24px', borderRadius: '10px', background: '#F0F8FD', flex: 1}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, background: '#37C9EF', borderRadius: '24px'}}>
            <Box component={'img'} src={`/img/teenyicons/appointments-blue.svg`}  width={24} height={24} />
          </Box>
          {nextInterview && <div>
            <Typography  sx={{fontWeight: 600, mb: .2}}>{DateTime.fromISO(nextInterview.date).toFormat('LLL d, h:mm a')} - {DateTime.fromISO(nextInterview.date).plus({hour: 1}).toFormat('h:mm a')}</Typography>
            <Typography onClick={() => navigate(candidateLink(false, {positionId: position.id, positionMatchId: nextInterview!.positionMatchId}))} sx={{cursor: 'pointer', textDecoration: 'underline'}}>Candidate {nextInterview.candidateNumber}</Typography>
          </div>}
          {!nextInterview && <Typography  sx={{fontWeight: 600}}>No upcoming interviews</Typography>}
        </Box>
      </Box>
    </Box>}

    <Box sx={{display: 'flex', gap: '5vw', mt: 5}}>
      <Box sx={{width: 1, '& p': {lineHeight: 1.5}}} dangerouslySetInnerHTML={{__html: position?.description || ''}} />
      <Box sx={{minWidth: 320, display: 'flex', flexDirection: "column", gap: 2}}>

        <PositionViewJobDetails position={position} />

        <PositionViewCurrentExperience position={position} />

      </Box>
    </Box>


    {onCandidatesClick && <div>
      <Button disabled={!!position.numberOfCandidates} onClick={onCandidatesClick} variant={'outlined'}>Candidates - {position.numberOfCandidates || 0}</Button>
    </div>}
  </Box>
}

export default PositionView;
