import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {client} from "./ApolloClient";
import {BrowserRouter, RouterProvider} from "react-router-dom";
import {ApolloProvider} from "@apollo/client";
import {closeSnackbar, SnackbarProvider} from "notistack";
import {IconButton, ThemeProvider} from "@mui/material";
import Theme from "./AppTheme";
import {StoryBook} from "./storybook/StoryBook";
import {routerRules} from "./routerRules";
import {KeycloakProvider} from "./keycloak";
import {ConfirmDialogProvider} from "./shared/ConfirmDialogContext";
import {ScrollContextProvider} from "./layout/context/ScrollContext";

const root = createRoot(document.getElementById('root') as HTMLElement)

const Snackbar = SnackbarProvider as any;

const action = (snackbarId: any) => (
  <IconButton
    onClick={() => {
      closeSnackbar(snackbarId)
    }}
    sx={{'&:hover': {backgroundColor: 'rgba(255,255,255, 0.1)', borderRadius: '50%'}}}
  >
    <img src={'/img/teenyicons/x-white.svg'} alt={""} width={16} height={16}/>
  </IconButton>
);

if (process.env.REACT_APP_STORYBOOK === 'true') {
  root.render(
    <ThemeProvider theme={Theme}>
      <Snackbar preventDuplicate action={action} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}
                autoHideDuration={6000}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <StoryBook/>
          </BrowserRouter>
        </ApolloProvider>
      </Snackbar>
    </ThemeProvider>
  );
} else {
  root.render(
    // <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Snackbar preventDuplicate action={action} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}
                autoHideDuration={6000}>
        <KeycloakProvider>
          <ApolloProvider client={client}>
            <ConfirmDialogProvider>
              <ScrollContextProvider>
                <RouterProvider router={routerRules}/>
              </ScrollContextProvider>
            </ConfirmDialogProvider>
          </ApolloProvider>
        </KeycloakProvider>
      </Snackbar>
    </ThemeProvider>
   // </React.StrictMode>
  );
}

