import React, {useState} from "react";
import {FileStorage, useUploadAnswerUrlMutation} from "../../../api/sdl";
import {enqueueSnackbar} from "notistack";
import config from "../../../config";
import {getFilesUrl} from "../../../shared";
import {VideoRecordModal, VideoRecordModalProps} from "../ui/VideoRecordModal";

type VideoRecordModalDataProviderProps = Omit<VideoRecordModalProps & {contractorId?: string, onRecorded: () => void}, "loading" | "url">

export const VideoRecordModalDataProvider = (props: VideoRecordModalDataProviderProps) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>()
  const [saveVideo] = useUploadAnswerUrlMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Video was uploaded successfully', {variant: 'success'});
    },
  });

  const onRecorded = React.useCallback( (blob: Blob) => {
    if (props.videoQuestionId !== undefined) {
      setUrl(undefined);
      setLoading(true)
      const formData = new FormData();

      formData.append('file', blob, props.contractorId + '_' + props.videoQuestionId + '.mp4');
      formData.append('contractorProfileId', props.contractorId || '');
      formData.append('prefix', 'video');

      fetch(
        config.uploadUrl + '/api/upload',
        {
          method: 'POST',
          body: formData,
          headers: {
          }
        }
      ).then(res => res.text()).then(res => {
        setUrl(getFilesUrl(FileStorage.S3) + '/' + res)
        return saveVideo({variables: {
            payload: {
              videoQuestionId: props.videoQuestionId,
              url: res,
            }
          }})
      }).then(props.onRecorded)
        .catch(() => {
          throw new Error('Record video failed')
        })
        .finally(() => setLoading(false))
      ;
    }
  }, [props.videoQuestionId])

  const onClose = () => {
    setUrl(undefined);
    setLoading(false);
    props.onClose();
  }

  return <VideoRecordModal {...props} loading={loading} onClose={onClose} onRecorded={onRecorded} url={url} />
}
