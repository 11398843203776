import {Box, Button, styled} from "@mui/material";
import React, {useContext} from "react";
import {useCancelMeetingApi} from "../api/useCancelMeetingApi";
import {RescheduleMeetingClickHandlerContext} from "../../../domain/matching_process/context/RescheduleMeetingClickHandlerContext";
import useIsContractor from "../../../shared/hooks/useIsContractor";
import {usePositionMatchStore} from "../../../domain/matching_process";
import {useResendMeetingMutation} from "../../../api/sdl";
import {useIsAdmin} from "../../../shared";
import {useSnackbar} from "notistack";

interface Props {
  meeting: {
    zoomMeetingLink?: string,
    id?: string,
  },
  onCancelMeeting: (meetingId: string) => void,
  onButtonClick?: () => void,
  positionMatchId?: string,
}

export function MeetingControlButtons({meeting, onCancelMeeting, positionMatchId, onButtonClick}: Props) {

  const isAdmin = useIsAdmin()
  const {data: store} = usePositionMatchStore()
  const { enqueueSnackbar } = useSnackbar();

  const [resendMeeting] = useResendMeetingMutation({variables: {meetingId: meeting.id!}})
  const cancelMeeting = useCancelMeetingApi(() => onCancelMeeting(meeting.id!));
  const {handler} = useContext(RescheduleMeetingClickHandlerContext);
  const isContractor = useIsContractor();

  const handleJoinClick = () => {
    onButtonClick && onButtonClick();
    window.open(`/interview-meeting-v2/${meeting.id}`)
  }

  const handleRescheduleClick = () => {
    onButtonClick && onButtonClick();
    if (positionMatchId && meeting.id) {
      handler({
        positionId:store!.positionId,
        positionMatchId,
        meetingId: meeting.id,
      });
    } else throw new Error('Meeting has no Id or positionMatchId')
  }

  const handleCancelClick = async (meetingId?: string) => {
    onButtonClick && onButtonClick();
    if (!meetingId) throw new Error('Meeting id is not set');
    await cancelMeeting(meetingId);
  }

  const handleResend = async () => {
    await resendMeeting()
    enqueueSnackbar('Will be resent in couple minutes', {variant: 'success'})

  }

  if(!store?.positionMatchId) console.error("PositionMatchId is missing")
  if(!store?.positionId) console.error("PositionId is missing")


  return <><Box sx={{mt: 2, display: 'flex', gap: 1, justifyContent: 'space-between'}}>
    <StyledButton variant={'text'}
                  onClick={handleJoinClick}>
      Join
    </StyledButton>

    {!isContractor &&
      <StyledButton variant={'text'}
                    data-test="card-reschedule-button"
                    onClick={handleRescheduleClick}>
        Reschedule
      </StyledButton>
    }

    {!isContractor &&
      <StyledButton variant={'text'}
                    onClick={() => handleCancelClick(meeting.id)}>
        Cancel meeting
      </StyledButton>
    }

  </Box>
    {isAdmin &&
      <Box>
         <StyledButton variant={'text'} onClick={handleResend}>Resend meeting emails</StyledButton>
      </Box>
    }
  </>
}

const StyledButton = styled(Button)({
  paddingLeft: 0,
  paddingRight: 0,
  fontWeight: 500,
  minWidth: 0
})