import {CompositeAnswerApiData} from "../lib/types";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography} from "@mui/material";
import {ExpandMoreIcon} from "./ExpandMoreIcon";
import React from "react";
import {Editable} from "../../../../../shared";

interface Props extends Editable{
  item: CompositeAnswerApiData,
  onEdit: () => void,
}
export function TextAnswer({item, onEdit, editable}: Props) {
  const data = item.textAnswer
  // {data!.question}

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onEdit()
  }
  return <Accordion sx={{
    boxShadow: 'none', // Remove elevation (box-shadow)
    border: '1px solid #ECEFEF', // Add custom border
    borderRadius: '4px', // Optional: Customize border radius
  }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
    >
      <Stack direction={"row"} sx={{width: 1}}>
{/*        <Box sx={{margin: 1, marginTop: '12px'}}>
          <TextAa size={22} color={"#77797A"}/>
        </Box>*/}
        <Box component={'img'} src={`/img/teenyicons/menu.svg`} width={22} height={22}
             sx={{margin: 1, marginTop: '16px'}}/>
        <Box sx={{width: 1}}>
          <Typography variant={"body1"} dangerouslySetInnerHTML={{__html: convertTextToHtml(item.title)}}
                      component={"div"}/>
        </Box>
        {editable &&
          <Box sx={{m: 1}}>
            <Button onClick={handleClick} variant={'outlined'} size={"small"} data-test={`edit-text-answer-${item.questionId}`}>{data ? "Edit" : "Add"}</Button>
          </Box>
        }
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{marginLeft: {xs: "50px", md: "100px"}}}>
        {data &&
            <Typography variant={"body1"} dangerouslySetInnerHTML={{__html: convertTextToHtml(data!.answer)}}
                        component={"div"}/>
        }

      </Box>
    </AccordionDetails>
  </Accordion>
}

function convertTextToHtml(input: string): string {
  const lines = input.split("\n");
  return lines.map(line => `<p>${line}</p>`).join("");
}
