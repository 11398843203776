import * as React from "react";
import {Box, Divider, Stack, Typography} from "@mui/material";
import {EditButton, Param, showRange, toCurrency, AttributesChips, Editable} from "../../../../../shared";

interface Props {
  callPointSpecialities: string[],
  roles: string[],
  salaryRangeMin?: number,
  salaryRangeMax?: number,
}

export default function View(props: Props & Editable) {
  const {callPointSpecialities, roles, salaryRangeMin, salaryRangeMax} = props;

  return <Stack spacing={3}>
    <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between', width: 1}}>
      <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>Healthcare industry experience</Typography>
      {props.editable && <EditButton onClick={props.onEdit} data-test={"current-experience-edit-button"} />}
    </Box>
    <AttributesChips sx={{ mt: 1, maxWidth: 200 }}
                     color={'info'}
                     attributes={roles} />

    {!!callPointSpecialities.length && <>
      <Divider />
      <Param title={'Call point specialities'}
             content={(
               <AttributesChips sx={{ mt: 1, maxWidth: 200 }}
                                color={'info'}
                                attributes={callPointSpecialities} />
             )}/>
    </>}

    <Divider />

    <Param title={'Salary'}
           content={(
             <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>
               {showRange(salaryRangeMin, salaryRangeMax, undefined, undefined, toCurrency)}
             </Typography>
           )}/>
  </Stack>
}
