import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
  Button
} from "@mui/material";
import React, {lazy} from "react";
import {
  useOneEmailTemplateLazyQuery,
  useSaveEmailTemplateMutation
} from "../../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import {withSuspense} from "../../../shared";

const TextEditor = withSuspense(lazy(() => import("../../../feature/text-editor/TextEditor")))

const schemaFields: any = {
  name: yup
    .string()
    .required('Name is required'),
  subject: yup
    .string()
    .required('Subject is required'),
  body: yup
    .string()
    .required('Body is required'),
}

export default function EmailForm() {
  const {id} = useParams()
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [save, {loading}] = useSaveEmailTemplateMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Template was saved successfully', {variant: 'success'})
      navigate('../emails')
    }
  });
  const [getEmail] = useOneEmailTemplateLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    if (id) {
      getEmail({variables: {id}}).then(response => {
        if (response.data?.oneEmailTemplate) {
          formik.setValues({
            name: response.data.oneEmailTemplate.name,
            subject: response.data.oneEmailTemplate.subject,
            body: response.data.oneEmailTemplate.body
          })
        }
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: {
      name: '',
      subject: '',
      body: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        save({
          variables: {
            entity: {
              id,
              ...values,
            }
          }
        })
      }
    },
    validateOnChange: true
  });

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 4}}>
      <Typography variant={'h3'} sx={{ml: 2}}>{id ? 'Edit' : 'Create'} email</Typography>
      <Button variant={'outlined'} color={'primary'}
              onClick={() => navigate('../emails')}>Back</Button>
    </Box>
    <Card sx={{mt: 3, p: 3}}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1, maxWidth: 1000}}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input placeholder="Enter name"
                   name="name"
                   value={formik.values.name}
                   onChange={formik.handleChange}
                   error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {(formik.touched.name && formik.errors.name) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.name}</FormHelperText>}
          </FormControl>
          <FormControl>
            <FormLabel>Subject</FormLabel>
            <Input placeholder="Enter name"
                   name="subject"
                   value={formik.values.subject}
                   onChange={formik.handleChange}
                   error={formik.touched.subject && Boolean(formik.errors.subject)}
            />
            {(formik.touched.subject && formik.errors.subject) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.subject}</FormHelperText>}
          </FormControl>
          <FormControl>
            <FormLabel>Body</FormLabel>
            <TextEditor value={formik.values.body} onChange={v => formik.setFieldValue('body', v)} />
            {(formik.touched.body && formik.errors.body) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.body}</FormHelperText>}
          </FormControl>
          <div>
            <Button disabled={loading} variant={'contained'} color={'primary'} type={'submit'}>{id ? 'Save' : 'Create'}</Button>
          </div>
        </Box>
      </form>
    </Card>
  </div>
}
