import {Skeleton, Stack} from "@mui/material";
import {useIsMobile} from "../../../../shared";

export function PositionsSkeleton() {
  const isMobile = useIsMobile()

  if(isMobile) {
    return <Stack spacing={2} direction={"column"}>

      <Stack direction={"row"} sx={{justifyContent: "space-between"}} spacing={2}>
        <Skeleton variant={"rounded"} width={100} height={50}/>
        <Skeleton variant={"rounded"} width={150} height={50}/>
      </Stack>

      <Skeleton variant={"rounded"} width={'100%'} height={250}/>
      <Skeleton variant={"rounded"} width={'100%'} height={250}/>
      <Skeleton variant={"rounded"} width={'100%'} height={250}/>

    </Stack>
  }

  return <Stack spacing={2} direction={"column"}>

    <Stack direction={"row"} sx={{justifyContent: "space-between"}} spacing={2}>

      <Skeleton variant={"rounded"} width={360} height={50}/>
      <Skeleton variant={"rounded"} width={200} height={50}/>
    </Stack>

    <Stack direction={"row"} spacing={3}>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
    </Stack>

    <Stack direction={"row"} spacing={3}>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
    </Stack>

    <Stack direction={"row"} spacing={3}>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
    </Stack>

    <Stack direction={"row"} spacing={3}>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
      <Skeleton variant={"rounded"} width={'100%'} height={200}/>
    </Stack>

  </Stack>
}