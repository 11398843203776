import * as React from 'react';
import {BottomNav} from "../../shared";
import {useEffect, useMemo, useState} from "react";
import {Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {ProfileContext} from "../../shared/ProfileContext";
import {ContractorStatus} from "../../api/sdl";

export default function OnboardingBottomNav() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [profile] = React.useContext(ProfileContext);

  useEffect(() => {
    switch (location.pathname) {
      case '/contractor/onboarding-completed': setIndex(1); break;
      default: setIndex(0);
    }
  }, [location.pathname]);

  const onChange = (i: number) => {
    setIndex(i);
    switch (i) {
      case 1: navigate('/contractor/onboarding-completed'); break;
      default: navigate('/contractor/personal-info');
    }
  }

  const items = useMemo(() => {
    const item1Disabled = !profile?.contractorProfile?.cvUrl;
    const item2Disabled = !profile?.contractorProfile?.cities;

    const getColor = (itemIndex: number) => {
      if ((itemIndex === 1 && item1Disabled) || (itemIndex === 2 && item2Disabled)) {
        return '#B9B9C6'
      }
      return index === itemIndex ? '#3075B8' : '#5D5E5F'
    }

    return [
      {
        active: index === 0,
        icon: <Typography variant={'body1'} data-test="step 1" sx={{color: getColor(0)}}><b>Step 1</b></Typography>,
        label: 'Personal\nbackground',
        disabled: profile?.contractorProfile?.status === ContractorStatus.OnboardingCompleted,
      },
      {
        active: index === 1,
        icon: <Typography variant={'body1'} data-test="step 2"sx={{color: getColor(1)}}><b>Step 2</b></Typography>,
        label: 'Confirmation',
        disabled: item1Disabled
      },
      // {
      //   active: index === 2,
      //   icon: <Typography variant={'body1'} data-test="step 3" sx={{color: getColor(2)}}><b>Step 3</b></Typography>,
      //   label: 'Employment\nhistory',
      //   disabled: item2Disabled,
      // },
    ]
  }, [index, profile?.contractorProfile?.cvUrl, profile?.contractorProfile?.cities]);

  return <BottomNav items={items}
                  index={index}
                  onChange={onChange}/>
}
