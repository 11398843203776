import {ContractorCardInnerProps, ContractorCardProps} from "../lib/types";
import {ReferenceType, useDeleteEmploymentHistoryMutation} from "../../../api/sdl";
import {Box, Divider, Stack, Typography} from "@mui/material";
import {EmploymentHistoryEditableList} from "../../../feature";
import {ContractorAwards} from "../../../feature/contractor-awards";
import {LeftSideContainer} from "./LeftSideContainer";
import React, {useContext, useState} from "react";
import ContractorCardBottomNav from "../../../contractor/components/ContractorCardBottomNav";
import {EducationEditableList} from "./education-list/EducationEditableList";
import {PersonalSummary} from "./personal-summary/PersonalSummary";
import {useIsMobile} from "../../../shared";
import {NotesDrawer} from "../../../domain/admin-interface/feature/notes-drawer/NotesDrawer";
import {CombinedAnswers} from "./combined-answers/CombinedAnswers";
import {ScrollContext} from "../../../layout/context/ScrollContext";

export function ContractorCard(props: ContractorCardProps) {
  const isMobile = useIsMobile();

  const [deleteEmploymentHistory] = useDeleteEmploymentHistoryMutation()

  const onWorkHistoryUpdate = async () => {
    props.onUpdate && props.onUpdate();
  }

  const onWorkHistoryDelete = (employmentHistoryId: string) => {
    return deleteEmploymentHistory({variables: {id: employmentHistoryId}}).then(() => {
      props.onUpdate && props.onUpdate();
    })
  }

  const name = {
    firstName: props.firstName || props.userProfile?.firstName,
    lastName: props.lastName || props.userProfile?.lastName
  };
  return isMobile
    ? <Mobile {...props} {...name} onWorkHistoryUpdate={onWorkHistoryUpdate} onWorkHistoryDelete={onWorkHistoryDelete} onUpdate={props.onUpdate} />
    : <Desktop {...props} {...name} onWorkHistoryUpdate={onWorkHistoryUpdate} onWorkHistoryDelete={onWorkHistoryDelete} onUpdate={props.onUpdate}/>
}

//-------------

const Desktop = (props: ContractorCardInnerProps) => {
  const {onWorkHistoryDelete, profile, editable, onUpdate, lastName, firstName, onVideoEditClick} = props;
  return <Box sx={{p: {xs: 0, md: 3}, mt: {xs: 7, md: 0}}}>
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Stack sx={{flex: 1, mr: {md: '5vw'}}} spacing={4}>

        <Stack direction={"row"} alignItems={"flex-start"}>
          <Typography sx={{fontSize: 34, fontWeight: 600, mb: 1}} variant={'h2'}>{firstName} {lastName}</Typography>
          <NotesDrawer reference={profile!.userId} referenceType={ReferenceType.Contractor} />
        </Stack>
        <PersonalSummary personalSummary={profile?.personalSummary}
                         updatableFromCv={!!profile?.hasParsedCv}
                         editable={editable}
                         onUpdate={onUpdate}
                         contractorId={profile?.contractorId} />
        <Divider sx={{my: 6}} />

        <EmploymentHistoryEditableList histories={profile?.employmentHistory}
                                       updatableFromCv={!!profile?.hasParsedCv}
                                       editable={editable}
                                       contractorId={profile!.contractorId}
                                       onItemDelete={onWorkHistoryDelete}
                                       onUpdate={props.onWorkHistoryUpdate} />

        <Divider sx={{my: 6}} />

        <EducationEditableList educations={props.educations || []}
                               editable={editable}
                               updatableFromCv={!!profile?.hasParsedCv}
                               contractorId={profile?.contractorId}
                               onUpdate={props.onWorkHistoryUpdate}
        />

        {!!props!.compositeAnswers && <>
            <Divider sx={{my: 6}} />
            <CombinedAnswers answers={props!.compositeAnswers} editable={editable} onUpdate={props.onWorkHistoryUpdate} contractorId={profile!.contractorId}/>
        </> }

        {/*<Videos onVideoEditClick={onVideoEditClick} profile={profile} editable={editable} onEdit={onUpdate} />*/}

        <Divider sx={{my: 6}} />

        <ContractorAwards editable={editable} awards={profile?.awards} />

        {/*<TypeformAnswers contractorId={profile!.contractorId} />*/}

      </Stack>

      <LeftSideContainer {...props} onEdit={onUpdate} />

    </Box>
  </Box>
}

//-------------

const Mobile = (props: ContractorCardInnerProps) => {
  const {onWorkHistoryDelete, profile, editable, onEdit, lastName, firstName, onVideoEditClick} = props;
  const [index, setIndex] = useState(0);
  const {scrollTop} = useContext(ScrollContext)

  const onNavClick = (newIndex: number) => {
    setIndex(newIndex);
    scrollTop()
  }

  return <Box sx={{p: 0, mt: 7}}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
      <Typography sx={{fontSize: 34, fontWeight: 600, mb: 5}} variant={'h2'}>{firstName} {lastName}</Typography>

      {index === 0 && <>
          <PersonalSummary personalSummary={profile?.personalSummary}
                           updatableFromCv={!!profile?.hasParsedCv}
                           editable={editable}
                           onEdit={onEdit}
                           contractorId={profile?.contractorId} />
          <LeftSideContainer {...props} />
      </>}
      {index === 1 && <>
        <EmploymentHistoryEditableList histories={profile?.employmentHistory}
                                                     updatableFromCv={!!profile?.hasParsedCv}
                                                     contractorId={profile?.contractorId}
                                                     editable={editable}
                                                     onUpdate={props.onWorkHistoryUpdate}
                                                     onItemDelete={onWorkHistoryDelete}
                                                     onEdit={onEdit} />

        <EducationEditableList educations={props.educations || []}
                               editable={editable}
                               updatableFromCv={!!profile?.hasParsedCv}
                               contractorId={profile?.contractorId}
                               onUpdate={props.onWorkHistoryUpdate}
        />
      </>}

      {index === 2 &&
        !!props?.compositeAnswers &&
                <CombinedAnswers answers={props!.compositeAnswers} editable={editable}
                                 onUpdate={props.onWorkHistoryUpdate} contractorId={profile!.contractorId}/>
      }

      {index === 3 && <>
          <ContractorAwards editable={editable} awards={profile?.awards}/>

      </> }


      <ContractorCardBottomNav index={index} onChange={onNavClick} />
    </Box>
  </Box>
}


