import {useNavigate, useParams} from "react-router-dom";
import {useIsAdmin, withSuspense} from "../../shared";
import React from "react";
import {ScheduleMeeting, usePositionMatchApi} from "../../domain/matching_process";
import {Box, Button, Container, Typography} from "@mui/material";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../../domain/matching_process/context/RescheduleMeetingClickHandlerContextProvider";
import {ArrowLeft} from "phosphor-react";
import {candidateLink, scheduleMeetingLink} from "../../entity/meeting/lib/utils";

function CandidateCardRescheduleInternal() {
  const navigate = useNavigate()
  const isAdmin = useIsAdmin();
  const {positionId, positionMatchId, meetingId} = useParams();

  // @ts-ignore
  const {positionMatch, meeting: currentMeeting } = usePositionMatchApi({positionMatchId, meetingId})

  const handleScheduleAgainClick = () => {
    navigate(scheduleMeetingLink({positionId: positionMatch!.positionId, positionMatchId: positionMatch!.id}))
  }

  const handleBackToProfileClick = () => {
    navigate(candidateLink(isAdmin, {positionId: positionId!, positionMatchId: positionMatchId!}))
  }

  const header = (name: string) => <Box sx={{my: 2}}>
    <Typography sx={{fontWeight: 700, fontSize: 18, my: 2}}>Reschedule interview with {name}</Typography>
    <Typography>Use this calendar to schedule an interview. Once you pick a time, you will be sent a calendar invite
      via email.</Typography>
  </Box>;

  if(!currentMeeting) {
    return null
  }

  return <RescheduleMeetingClickHandlerContextProvider>
    <Container sx={{my: 4}} maxWidth={'lg'}>
      <Box>
        <Button variant={'text'} startIcon={<ArrowLeft size={20} color="#3075B8" weight="regular"/>}
                onClick={handleBackToProfileClick}>Back to profile</Button>
      </Box>
      <ScheduleMeeting positionMatchId={positionMatchId!!}
                       meetingId={meetingId}
                       meetingListTitle={'Other interviews with this candidate'}
                       onScheduleClick={handleScheduleAgainClick}
                       onMeetingScheduled={() => {}}
                       slots={{ header }}
      />
    </Container>
  </RescheduleMeetingClickHandlerContextProvider>
}

export const CandidateCardReschedule = withSuspense(CandidateCardRescheduleInternal)
