import {Box, Stack} from "@mui/material";
import React from "react";
import {NavLink, useRouteError} from "react-router-dom";
import {NotFound} from "../not-found/NotFound";

export function ErrorBoundary() {

  const error = useRouteError() as any;

  console.error(error.stack)
  console.error(error)
  if(error.name ==='AccessDeniedError') {
    return <AccessDenied />
  }

  if(error.status == 404) {
    return <NotFound />
  }

  return <DefaultError />
}

function DefaultError() {
  return <Stack sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      marginTop: "-10vh",
  }} spacing={2}>
      <Box component={'img'} src={`/img/broken_robot_round_head_pictogram.svg`} width={250} height={250}/>
      <h1>Something went wrong</h1>
      <p>Please, try again later.</p>
    </Stack>
}

function AccessDenied() {
  return <Stack sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    marginTop: "-10vh"
  }} spacing={2}>
    <Box component={'img'} src={`/img/lock.svg`} width={250} height={250}/>
    <h1>Access Denied</h1>
    <p>Looks like you don't have access to this resource</p>
    <NavLink to={"/"}>Navigate to dashboard</NavLink>
  </Stack>
}