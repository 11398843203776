import {useNavigate} from "react-router-dom";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import React, {lazy, useRef} from "react";
import {withOnboardingLayout} from "./OnboardingLayout";
import {ProfileContext} from "../../../shared/ProfileContext";
import {
  AttributeCategory,
  ContractorStatus,
  useContractorAttributesByCategoryQuery,
  useSaveContractorProfileMutation,
  useSaveProfileAttributeMutation,
} from "../../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import {Download} from "phosphor-react";
import {useSnackbar} from "notistack";
import {getFilesUrl, withSuspense} from "../../../shared";
import {LoadingButton} from "@mui/lab";
import {CitySelector} from "../../../feature/city-selector";
import {CityFormValue} from "../../../feature/city-selector/lib/types";
import {UploadResumeButton} from "../../../feature";
const TextEditor = withSuspense(lazy(() => import("src/feature/text-editor/TextEditor")))


const schemaFields: any = {
  roles: yup
    .array()
    .min(1, 'You must choose at least one')
    .max(5, 'You must choose no more than five'),
  specialities: yup
    .array()
    .min(1, 'You must choose at least one')
    .max(5, 'You must choose no more than five'),

  linkedInUrl: yup
    .string(),
  personalSummary: yup
    .string(),
  cities: yup
    .array()
    .min(1, 'You must choose at least one')
    .max(5, 'You must choose no more than five'),
}

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [profile, refresh] = React.useContext(ProfileContext);
  const [saveProfile, {loading}] = useSaveContractorProfileMutation();
  const [saveAttributes, {loading: attributesLoading}] = useSaveProfileAttributeMutation();
  const [cvUrl, setCvUrl] = React.useState('');
  const [fileLoading, setFileLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);

  React.useEffect( () => {

    if(profile?.contractorProfile?.status === ContractorStatus.OnboardingCompleted) {
      navigate('/contractor/onboarding-completed');
    }
  }, [profile]);

  const rolesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.RoleCategory}});
  const roles = React.useMemo(() => {
    return rolesData.data?.contractorAttributesByCategory || [];
  }, [rolesData.data]);

  const specialitiesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.CallPointSpeciality}});
  const specialities = React.useMemo(() => {
    return specialitiesData.data?.contractorAttributesByCategory || [];
  }, [specialitiesData.data]);

  const formik = useFormik({
    initialValues: {
      roles: [] as string[],
      specialities: [] as string[],
      linkedInUrl: '',
      personalSummary: '',
      cities: [] as string[],
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (!cvUrl) {
        enqueueSnackbar('You have to upload Resume', {variant: 'error'});
      }
      if (formik.isValid && cvUrl) {
        saveProfile({
          variables: {
            payload: {
              linkedInUrl: values.linkedInUrl,
              personalSummary: values.personalSummary,
              cities: values.cities,
            }
          }
        }).then(() => saveAttributes({
          variables: {
            payload: [{
              category: AttributeCategory.RoleCategory,
              ids: values.roles
            }, {
              category: AttributeCategory.CallPointSpeciality,
              ids: values.specialities
            }]
          }
        })).then(refresh).then(() => navigate('/contractor/onboarding-completed'))
      }
    },
    validateOnChange: true
  });

  React.useEffect(() => {
    if (profile) {
      formik.setValues({
        linkedInUrl: profile.contractorProfile?.linkedInUrl || '',
        personalSummary: profile.contractorProfile?.personalSummary || '',
        roles: profile.contractorProfile?.attributes.filter(a => a.category === AttributeCategory.RoleCategory).map(a => a.id) || [] as string[],
        specialities: profile.contractorProfile?.attributes.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.id) || [] as string[],
        cities: [],
      })
      if (profile.contractorProfile?.cvUrl) {
        setCvUrl(profile.contractorProfile?.cvUrl);
      }
    }
  }, [profile]);

  const updateCities = (values: string[]) => {

    const initialValue = profile?.contractorProfile?.cities.map((it) => it.id)
    if(formik.touched.cities && initialValue !== values) {
      formik.setFieldTouched('cities', true)
    }

    formik.setFieldValue('cities', values)
  }


  return <Box>
    <Typography data-test='step-title'
                variant={'h3'}
                sx={{mb: 3, fontWeight: '500!important'}}>Help us out by providing some additional
      information about your professional background</Typography>
    <Box component={'form'} flex={4} onSubmit={formik.handleSubmit}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1, maxWidth: 520}}>
        <div ref={ref} />
        <Box>
          <CitySelector onChange={updateCities} value={profile?.contractorProfile?.cities as CityFormValue[] || []} />
          {/*{JSON.stringify(formik.errors)}*/}
          {/*{JSON.stringify(formik.touched)}*/}
          {(formik.touched.cities && formik.errors.cities) && <FormHelperText data-test='role-error' sx={{color: '#D3232F'}}>{formik.errors.cities}</FormHelperText>}
        </Box>
        <FormControl disabled={!roles.length} error={formik.touched.roles && !!formik.errors.roles}>
          <FormLabel >
            Healthcare industry experience* <Typography component={"span"} variant={"body2"}>(choose up to five)</Typography>
          </FormLabel>
          <Autocomplete
            multiple
            placeholder="Select roles"
            data-test='role'
            limitTags={2}
            options={roles.map(a => a.id)}
            value={formik.values.roles}
            onChange={(event, newValue) => {
              formik.setFieldValue('roles', newValue);
            }}
            getOptionLabel={o => roles.find(r => r.id === o)?.attribute || ''}
            sx={{ width: {xs: 1, md: '500px'}}}
            renderInput={(params) => <TextField {...params} />}
          />
          {(formik.touched.roles && formik.errors.roles) && <FormHelperText data-test='role-error' sx={{color: '#D3232F'}}>{formik.errors.roles}</FormHelperText>}

        </FormControl>
        <FormControl error={formik.touched.specialities && !!formik.errors.specialities}>
          <FormLabel id="category-select-label" htmlFor="category-select">
            Call point specialities* <Typography component={"span"} variant={"body2"}>(choose up to five)</Typography>
          </FormLabel>
          <Autocomplete
            multiple
            onChange={(event, newValue) => {
              formik.setFieldValue('specialities', newValue);
            }}
            placeholder="Select specialities"
            data-test='specialist'
            limitTags={2}
            value={formik.values.specialities}
            getOptionLabel={o => specialities.find(r => r.id === o)?.attribute || ''}
            options={specialities.map(a => a.id)}
            sx={{ width: {xs: 1, md: '500px'}}}
            renderInput={(params) => <TextField {...params} />}
          />
          {(formik.touched.specialities && formik.errors.specialities) && <FormHelperText data-test='specialist-error' sx={{color: '#D3232F'}}>{formik.errors.specialities}</FormHelperText>}
        </FormControl>
        <FormControl>
          <FormLabel>LinkedIn profile URL</FormLabel>
          <TextField placeholder="e.g. https://www.linkedin.com/in/firstname-lastname/"
                 name="linkedInUrl"
                 data-test='url-profile'
                 value={formik.values.linkedInUrl}
                 onChange={formik.handleChange}
                 error={formik.touched.linkedInUrl && Boolean(formik.errors.linkedInUrl)}
                 sx={{ width: {xs: 1, md: '500px'}}}
          />
          {(formik.touched.linkedInUrl && formik.errors.linkedInUrl) && <FormHelperText data-test='url-error' sx={{color: '#D3232F'}}>{formik.errors.linkedInUrl}</FormHelperText>}
        </FormControl>
        <FormControl>
          <FormLabel>Please give us a professional statement in 1-3 sentences about your past healthcare sales experience and what type of sales roles you are looking for.</FormLabel>
          <TextEditor value={formik.values.personalSummary} onChange={v => formik.setFieldValue('personalSummary', v)} />
          {(formik.touched.personalSummary && formik.errors.personalSummary) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.personalSummary}</FormHelperText>}
        </FormControl>

        <Box sx={{marginBottom: -2}}>
          <FormLabel>Upload your resume in PDF format. No DOC format will be accepted.</FormLabel>
        </Box>

        <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1, width: '300px'}}>
            <UploadResumeButton  onUpload={setCvUrl}/>
          </Box>
          <Box>
            {cvUrl && <LoadingButton
              onClick={() => {
                const url = getFilesUrl(profile?.contractorProfile?.fileStorage) + '/' + cvUrl;
                window.open(url);
              }}
              loading={fileLoading}
              size="small"
              data-test="download"
              variant="text"
              color="secondary"
              startIcon={<Download size={24} color="#128EAF" weight="fill" />}
            >
              Download
            </LoadingButton>}
          </Box>

        </Box>

        <Box sx={{mt: 3, mb: 3, display: 'flex', justifyContent: 'space-between'}}>
          <LoadingButton loading={loading || attributesLoading}
                  onClick={() => {
                    if (!formik.isValid) {
                      (ref.current as any)?.scrollIntoView({behavior: 'smooth', block: 'center'})
                    }
                  }}
                  variant="contained"
                  type="submit">Submit</LoadingButton>
        </Box>
      </Box>
    </Box>
  </Box>;
}

export default withOnboardingLayout(PersonalInfo)
