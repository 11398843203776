import {PositionOutput, PositionStatus, useMyPositionsSuspenseQuery} from "../../../../api/sdl";
import {PositionFilter} from "../Positions";

export function useCompanyPositionsApi(filter: PositionFilter) {
  const {data, refetch} = useMyPositionsSuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        orderBy: filter.orderBy,
        status: filter.status === 'all' ? undefined : filter.status as PositionStatus
      },
      page: {page: 0, size: 1000}
    }

  });

  const positions = data?.myPositions.data as PositionOutput[]
  return {
    positions,
    refetch
  }
}