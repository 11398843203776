import React from "react";
import {PositionByIdQuery, usePositionByIdSuspenseQuery} from "../../api/sdl";

export function usePositionByIdApi(id: string) : {
  position: PositionApiData,
  refetch: any
} {
  const {data, refetch} = usePositionByIdSuspenseQuery({variables: {id}});

  const position = React.useMemo(() => {
    return data!.positionById
  }, [data])

  // TypeScript type narrowing
  if (!data || !data.positionById) {
    throw new Error("Data should never be undefined when using Suspense.");
  }

  // @ts-ignore
  return {position, refetch};
}

export type PositionApiData = NonNullable<PositionByIdQuery["positionById"]>