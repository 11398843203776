import {Container, Skeleton, Stack} from "@mui/material";
import {useIsMobile} from "../../../../../shared";
import {DefaultSkeleton} from "../../../../../shared/hooks/withSuspense";

export function CandidatesPageSkeleton() {
  const isMobile = useIsMobile()
  if(!isMobile) {
    return <Container maxWidth={'lg'}>
      <Stack direction={"column"} spacing={3}>
        <Stack direction={"row"} spacing={2}>
          <Skeleton variant={"rounded"} width={250} height={100}/>
          <Skeleton variant={"rounded"} width={250} height={100}/>
          <Skeleton variant={"rounded"} width={250} height={100}/>
        </Stack>

        <Stack direction={"row"} spacing={2}>
          <Stack direction={"column"} spacing={2} sx={{width: '100%'}}>
            <Skeleton variant={"rounded"} width={'100%'} height={100}/>
            <Skeleton variant={"rounded"} width={'100%'} height={600}/>
            <Skeleton variant={"rounded"} width={'100%'} height={400}/>
            <Skeleton variant={"rounded"} width={'100%'} height={300}/>
          </Stack>
          <Stack direction={"column"} spacing={2} sx={{width: '350px'}}>
            <Skeleton variant={"rounded"} width={'100%'} height={50}/>
            <Skeleton variant={"rounded"} width={'100%'} height={100}/>
            <Skeleton variant={"rounded"} width={'100%'} height={370}/>
          </Stack>
        </Stack>

      </Stack>
    </Container>
  }

  return <DefaultSkeleton />
}