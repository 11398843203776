import {Box, Button} from "@mui/material";

import React from "react";
import {InternalProps} from "../lib/types";
import PublishMenu from "../../../page/position-page/ui/PublishMenu";
import {PositionStatus} from "../../../../../api/sdl";
import {usePositionStatusApi} from "../../../page/position-page/hooks/usePositionStatusApi";
import ActionsMenu from "../../../page/position-page/ui/ActionsMenu";

export function PositionEditingButtons({position, positionId, refetch, setEditMode, editMode}: InternalProps) {
  const statusApi = usePositionStatusApi({
    refresh: () => {
      refetch({id: positionId})
    },

    positionId: positionId
  });

  return <>
    <Box sx={{display: 'flex', gap: '12px'}}>
      {positionId && <PublishMenu positionId={positionId} positionStatus={position?.status} api={statusApi}/>}
      {positionId && position?.status !== PositionStatus.Open &&
          <Button onClick={() => {
            statusApi.publish()
          }} variant={'contained'} data-test={"publish-rolw-button"}>Publish</Button>}
      {positionId && <ActionsMenu positionId={positionId}/>}
      {!editMode &&
          <Button disabled={!position} variant={'outlined'} onClick={() => setEditMode(true)}>Edit role</Button>}
    </Box>
  </>
}