import {useNavigate, useParams} from "react-router-dom";
import {
  PositionMatchStatus,
  usePositionMatchSuspenseQuery
} from "../../api/sdl";
import {ContractorCard} from "../contractor-card";
import {Box, Button, Container, Skeleton, Stack, Typography} from "@mui/material";
import {expectedSalaryFromPositionMatch, useIsMobile, withSuspense} from "../../shared";
import {MeetingList} from "../../domain/matching_process/feature/meeting-list/MeetingList";
import React from "react";
import {convertMeetingsFromApiData} from "../../domain/matching_process/api/usePositionMatchApi";
import useRejectCandidateClick from "../../domain/matching_process/api/useRejectCandidateClick";
import {DefaultSkeleton} from "../../shared/hooks/withSuspense";
import {useCandidateStore} from "../candidate-carousel-layout/lib/useCandidateStore";

function CandidateCardPreviewInternal() {
  const navigate = useNavigate()
  const {positionMatchId, positionId} = useParams()
  const {data, refetch} = usePositionMatchSuspenseQuery({fetchPolicy: "no-cache", variables: {positionMatchId: positionMatchId}})
  const {fetchData} = useCandidateStore(positionId!)

  const currentCandidate = data?.positionMatch

  const onRejectClick = useRejectCandidateClick(refetch, currentCandidate?.id);

  const onScheduleClick = () => {
    navigate(`../${positionMatchId}/schedule`)
  }

  const onCancelMeeting = async (meetingId: string) => {
    await refetch();
    await fetchData();
  }

  const meetings = convertMeetingsFromApiData(data!.positionMatch?.meetings) || [];
  const hasContract = false;
  if(!currentCandidate) {
    return null
  }


  return <>
    <ContractorCard profile={currentCandidate.contractorProfile}
                    firstName={currentCandidate.displayName}
                    lastName={undefined}
                    showSideStripe={false}
                    educations={currentCandidate.educations}
                    compositeAnswers={data.positionMatch.compositeAnswers}
                    extraContent={<>
                      {(currentCandidate?.status !== PositionMatchStatus.CompanyScheduledCall && currentCandidate?.status !== PositionMatchStatus.CompanyDeclined) &&
                          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                              <Button data-test='reject'
                                      variant={'outlined'}
                                      color={'warning'}
                                      sx={{width: '140px'}}
                                      onClick={onRejectClick}>
                                  Reject
                              </Button>

                              <Button data-test='approve'
                                      variant={'contained'}
                                      fullWidth
                                      onClick={onScheduleClick}>
                                  Schedule meeting
                              </Button>
                          </Box>}
                      {currentCandidate?.status === PositionMatchStatus.CompanyDeclined &&
                          <Typography sx={{fontWeight: 600}}>Rejected</Typography>}

                      {(!!currentCandidate.expectedRate) && <Box sx={{
                        background: '#F0F8FD',
                        border: '1px solid #DDE1E3',
                        borderRadius: '10px',
                        p: '20px 24px'
                      }}>
                          <Typography variant={"body1"} fontSize={16} fontWeight={600}>Salary
                              Requirement:</Typography>
                          <Typography variant={"body1"}
                                      sx={{paddingTop: 1}}>{expectedSalaryFromPositionMatch(currentCandidate)}</Typography>
                      </Box>}

                      {(currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall && !hasContract) && (
                        <Box sx={{mb: 2}}>
                          <Button data-test='hire'
                                  sx={{width: 1,}}
                                  onClick={() => navigate('/company/hire/' + positionId + '/' + currentCandidate?.id)}
                                  variant="contained">
                            Hire Contractor
                          </Button>
                        </Box>
                      )}

                      <MeetingList meetings={meetings}
                                   onScheduleClick={onScheduleClick}
                                   onCancelMeeting={onCancelMeeting}
                                   showNextMeetingButton={currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall}
                                   positionMatchId={currentCandidate!.id}
                                   title={'Scheduled interviews with this candidate'}/>
                    </>}
    />
  </>
}

export const CandidateCardPreview = withSuspense(CandidateCardPreviewInternal, <ComponentSkeleton />)

export function ComponentSkeleton() {
  const isMobile = useIsMobile()
  if(!isMobile) {
    return <Container maxWidth={'lg'}>
      <Stack direction={"column"} spacing={3}>
        <Stack direction={"row"} spacing={2}>
          <Stack direction={"column"} spacing={2} sx={{width: '100%'}}>
            <Skeleton variant={"rounded"} width={'100%'} height={100}/>
            <Skeleton variant={"rounded"} width={'100%'} height={600}/>
            <Skeleton variant={"rounded"} width={'100%'} height={400}/>
            <Skeleton variant={"rounded"} width={'100%'} height={300}/>
          </Stack>
          <Stack direction={"column"} spacing={2} sx={{width: '350px'}}>
            <Skeleton variant={"rounded"} width={'100%'} height={50}/>
            <Skeleton variant={"rounded"} width={'100%'} height={100}/>
            <Skeleton variant={"rounded"} width={'100%'} height={370}/>
          </Stack>
        </Stack>

      </Stack>
    </Container>
  }

  return <DefaultSkeleton />
}