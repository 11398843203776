import {client} from "../../ApolloClient";
import {gql} from "graphql-tag";
import {keycloak} from "../../keycloak";
import {hasKeycloakRole} from "../../shared";

export const adminMenu = async () => {
    const notifications = client.query({
        query: gql`{myNotificationCounter}`,
      fetchPolicy: 'no-cache'
    })
  const result = {
    top: [
      {
        title: 'Notifications',
        route: '/admin/notifications',
        icon: 'bell',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'Contractors',
        route: '/admin/contractors',
        icon: 'users',
      },
      {
        title: 'Companies',
        route: '/admin/companies',
        icon: 'briefcase',
      },
      {
        title: 'Positions',
        route: '/admin/positions',
        icon: 'users',
      },
      {
        title: 'Contracts',
        route: '/admin/contracts',
        icon: 'briefcase',
      },

      {
        title: 'Invoices',
        route: '/admin/invoices',
        icon: 'briefcase',
      },
      {
        title: 'My meetings',
        route: '/admin/meetings',
        icon: 'appointments',
      },
      {
        title: 'External contractors',
        route: '/admin/external',
        icon: 'users',
      },
    ],
    bottom: [
      {
        title: 'Attributes',
        route: '/admin/attributes',
        icon: 'cog',
      },
      {
        title: 'Email templates',
        route: '/admin/emails',
        icon: 'envelope',
      },
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => keycloak.logout({redirectUri: document.location.origin})
      }
    ]
  }

  if (hasKeycloakRole('ROLE_TECH_ADMIN')) {
    result.bottom.unshift({
      title: 'Service',
      route: '/admin/service',
      icon: 'cog',
    })
  }

  return result;
}
