import React, {useState} from "react";
import {Box, IconButton, Stack, styled} from "@mui/material";
import {CaretLeft, CaretRight} from "phosphor-react";
import CandidateCard from "./CandidateCard";
import {expectedSalaryFromPositionMatch, useIsMobile} from "../../../../shared";
import {CandidateListApiData} from "../lib/types";

type Props = {
  candidates: CandidateListApiData[],
  onCardClick: (index: number) => void,
  active?: string
}

const CandidateCarousel = ({candidates, active, onCardClick}: Props) => {
  const isMobile = useIsMobile()

  const wrapperRef = React.useRef();
  const [scrollIndex, setScrollIndex] = useState(0)

  const handleCardClick = (e: any, index: number) => {
    onCardClick(index)
  }

  const handleLeftClick = () => {
    const container = (wrapperRef.current as any)
    if(container.scrollLeft > 0 && scrollIndex > 0) {
      const index = scrollIndex - 1
      setScrollIndex(index)
      scrollToElement(container, index)
    }
  }

  const handleRightClick = () => {
    const container = (wrapperRef.current as any)
    const scrollRight = container.scrollWidth - container.scrollLeft - container.offsetWidth
    if(scrollRight > 0) {
      const index = scrollIndex + 1
      setScrollIndex(index)
      scrollToElement(container, index)
    }
  }

  const scrollToElement = (container: HTMLElement, index: number) => {
    const targetElement = container.children[index] as HTMLElement;
    const position = targetElement.offsetLeft - container.offsetLeft;
    container.scrollTo({
      left: position,
      behavior: 'smooth', // Smooth scrolling effect
    })
  }

  return <Box >

    <Stack direction={"row"}>

      {!isMobile && <Box sx={{display: "flex", alignItems:"center"}}>
          <IconButton
              onClick={() => handleLeftClick()}>
              <CaretLeft size={18} color="#2C92D5" weight="regular"/>
          </IconButton>
      </Box>}

      <CandidatesContainerStyled ref={wrapperRef}>
        {candidates.map((c, i) => (
          <CandidateItemWrapperStyled key={i}>
            <CandidateCard
              status={c.status!!}
              displayName={c.displayName}
              salary={expectedSalaryFromPositionMatch(c)}
              location={formatLocation(c)}
              onClick={(e: any) => handleCardClick(e, i)}
              meetings={c.meetings}
              active={!!active && active === c.id}/>
          </CandidateItemWrapperStyled>
        ))}
      </CandidatesContainerStyled>

      {!isMobile &&
          <Box sx={{display: "flex", alignItems:"center"}}>
              <IconButton
                  onClick={() => handleRightClick()}>
                  <CaretRight size={18} color="#2C92D5" weight="regular"/>
              </IconButton>
          </Box>
      }

    </Stack>
  </Box>
}

export default CandidateCarousel;

const formatLocation = (c: CandidateListApiData) => (
  c.contractorProfile.cities.map((city) => `${city.city}, ${city.stateShort}`).join("; ")
)

const CandidatesContainerStyled = styled(Box)({
  display: 'flex',
  gap: 20,
  overflowX: 'auto',
  scrollSnapType: 'x mandatory', // Apply snap effect
  '&::-webkit-scrollbar': {
    display: 'none', // Hide the scrollbar for cleaner UI (optional)
  },
});

const CandidateItemWrapperStyled = styled(Box)({
  display: "flex",
  flexShrink: 0,
  scrollSnapAlign: 'start',
})